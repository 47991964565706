
import { useNavigate } from 'react-router-dom'
import FromBanner from './FromContent'

import { apiPostAds } from '../../modules/auth/core/_requestAds'

const CreateWrapper = () => {
  const navigate = useNavigate();
  const enableSplashScreen = () => {
    const splashScreen = document.getElementById('splash-screen')
    if (splashScreen) splashScreen.style.setProperty('display', 'flex')
  }

  const onSubmit = (value) => {
    enableSplashScreen()
    apiPostAds(value)
      .then(console.log)
      .catch(console.error)
      .finally(() => navigate('/ads'))
  }

  return (
    <div className="container" style={{ minHeight: '90vh' }}>
      <FromBanner type="create" onSubmit={onSubmit} onClickOntrend={() => { }} onCancel={() => navigate('/ads')} />
    </div>
  )
}

export default CreateWrapper
