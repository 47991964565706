

// import axios from 'axios'
import Cookies from 'js-cookie'
// import { get } from 'lodash'
import { USER_TOKEN_KEY } from './_cookie'

const getToken = async () => {
  const _token = await Cookies.get(USER_TOKEN_KEY)
  if (Boolean(_token))
    return _token

  return ""

  // const headers = {
  //   Accept: "application/json",
  //   Authorization: `Bearer ${tokenCMS}`,
  //   "Content-Type": "application/json",
  // }

  // const token = await axios({ method: 'POST', url: REQUEST_AUTH, headers: headers })
  //   .then(async (res) => {
  //     const data = get(res, 'data.data.token', '')
  //     await Cookies.set(_tokenName, data)
  //     return data
  //   }).catch(() => {

  //     return ""
  //   })

  // return token
}

export const headerAuth = async (data) => {
  const _headers = {
    Accept: "application/json",
    Authorization: `Bearer ${data}`,
    "content-type": "application/json",
  };

  return _headers
}

export const header = async () => {
  let token = await getToken()
  return {
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  };
}

export const headerMultipart = async () => {
  let token = await getToken()
  return {
    Accept: "multipart/form-data",
    Authorization: `Bearer ${token}`,
    "Content-Type": "multipart/form-data",
  };
}

export const headerCmsSteam = async () => {
  let token = await getToken()
  return {
    Authorization: `Bearer ${token}`,
    'Content-Type': 'application/octet-stream'
  };
}