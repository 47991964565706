import axios from 'axios'

import { header } from './_header'
import { REQUEST_CMS_USER, REQUEST_CMS_LOGIN } from './_endpoints'

export async function apiUserLogin(payload) {
  const headers = await header()
  return axios({ headers, method: 'POST', url: `${REQUEST_CMS_LOGIN}`, data: payload })
}

export async function apiGetUser() {
  const headers = await header()
  return axios({ headers, method: 'GET', url: `${REQUEST_CMS_USER}` })
}

export async function apiCreateUser() {
  const headers = await header()
  return axios({ headers, method: 'POST', url: `${REQUEST_CMS_USER}` })
}

export async function apiUpdaetUser(payload) {
  const headers = await header()
  return axios({ headers, method: 'PUT', url: `${REQUEST_CMS_USER}`, data: payload })
}

export async function apiDeleteUser(id) {
  const headers = await header()
  return axios({ headers, method: 'DELETE', url: `${REQUEST_CMS_USER}/${id}` })
}
