import { useEffect, useState } from 'react'
import { get } from 'lodash'
import Cookies from 'js-cookie'

import FromConfig from './FromConfig'

import { USER_TOKEN_KEY } from '../../modules/auth/core/_cookie'
import { callapiFile } from '../../modules/auth/core/_requestContent'
import { apiGetConfig, apiCreateOrUpdateConfig } from '../../modules/auth/core/_requestConfig'

const ID = 'main'
const ConfigWrapper = () => {
  const [content, setContent] = useState([])

  const enableSplashScreen = () => {
    const splashScreen = document.getElementById('splash-screen')
    if (splashScreen) splashScreen.style.setProperty('display', 'flex')
  }

  const disableSplashScreen = () => {
    const splashScreen = document.getElementById('splash-screen')
    if (splashScreen) splashScreen.style.setProperty('display', 'none')
  }

  const getConfig = () => {
    enableSplashScreen()
    apiGetConfig(ID)
      .then((res) => setContent(JSON.parse(get(res, 'data.data.data', ""))))
      .catch((error) => {
        if (String(error?.response?.data?.message || "").includes("Token")) {
          enableSplashScreen()
          Cookies.remove(USER_TOKEN_KEY)
          setTimeout(() => {
            window.location.reload()
          }, 500)
        }
      })
      .finally(() => setTimeout(() => { disableSplashScreen() }, 200))
  }

  const setConfig = async (value) => {
    enableSplashScreen()

    // check image
    if (typeof value.file === 'object' && value.file !== null) {
      const image = await new Promise((resolve, reject) => {
        callapiFile(value.file)
          .then((res) => resolve({ logo_image: get(res, 'data.data.filePath', '') }))
          .catch(reject)
      })

      if (image?.logo_image !== "")
        value = Object.assign({}, value, { logo_image: image?.logo_image })
    }

    // check file_meta
    if (typeof value.file_meta === 'object' && value.file_meta !== null) {
      const image_file_meta = await new Promise((resolve, reject) => {
        callapiFile(value.file_meta)
          .then((res) => resolve({ meta_image: get(res, 'data.data.filePath', '') }))
          .catch(reject)
      })

      if (image_file_meta?.meta_image !== "")
        value = Object.assign({}, value, { meta_image: image_file_meta?.meta_image })
    }

    apiCreateOrUpdateConfig(ID, value)
      .then(console.log)
      .catch(console.error)
      .finally(() => getConfig())
  }

  useEffect(() => {
    getConfig()
    // eslint-disable-next-line
  }, [])

  return <div className="container" style={{ minHeight: '90vh' }}>
    {content?.site_info && <FromConfig value={content} onSubmit={setConfig} />}
  </div>
}

export default ConfigWrapper