import { Route, Routes, Navigate } from 'react-router-dom'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'

// content
import { ContentWrapper } from '../pages/Contents'
import { EditContentWrapper } from '../pages/Contents/edit'
import { CreateContentWrapper } from '../pages/Contents/create'

// page
import TermAndConditionsWrapper from '../pages/TermAndConditions'
import { AboutUsWrapper } from '../pages/AboutUs'

// banner
import BannerCoverPageWrapper from '../pages/BannerCoverPage'
import EditBannerCoverPageWrapper from '../pages/BannerCoverPage/edit'
import CreateBannerCoverPageWrapper from '../pages/BannerCoverPage/create'

// live
import LiveWrapper from '../pages/Live'
import EditLiveWrapper from '../pages/Live/edit'
import CreateLiveWrapper from '../pages/Live/create'

// config
import ConfigWrapper from '../pages/Config'
import UserWrapper from '../pages/User'

// program
import ProgramTVWrapper from '../pages/ProgramTV'

// help me
import HelpmeWrapper from '../pages/HelpMe'

// Ads
import AdsWrapper from '../pages/Ads'
import EditAdsWrapper from '../pages/Ads/edit'
import CreateAdsWrapper from '../pages/Ads/create'

const PrivateRoutes = () => {
  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />

        {/* content */}
        <Route path='content' element={<ContentWrapper />} />
        <Route path='content/create' element={<CreateContentWrapper />} />
        <Route path='content/edit/:id' element={<EditContentWrapper />} />

        {/* banner */}
        <Route path='banner/cover_page' element={<BannerCoverPageWrapper />} />
        <Route path='banner/cover_page/create' element={<CreateBannerCoverPageWrapper />} />
        <Route path='banner/cover_page/edit/:id' element={<EditBannerCoverPageWrapper />} />

        {/* live */}
        <Route path='live' element={<LiveWrapper />} />
        <Route path='live/create' element={<CreateLiveWrapper />} />
        <Route path='live/edit/:id' element={<EditLiveWrapper />} />

        {/* help me */}
        <Route path='help' element={<HelpmeWrapper />} />

        {/* page */}
        <Route path='page/about-us' element={<AboutUsWrapper />} />
        <Route path='page/term-and-conditions' element={<TermAndConditionsWrapper />} />

        {/* config */}
        <Route path='config' element={<ConfigWrapper />} />
        <Route path='user' element={<UserWrapper />} />

        {/* program */}
        <Route path='program/:id' element={<ProgramTVWrapper />} />

        {/* ads */}
        <Route path='ads' element={<AdsWrapper />} />
        <Route path='ads/create' element={<CreateAdsWrapper />} />
        <Route path='ads/edit/:id' element={<EditAdsWrapper />} />

        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/content' />} />


      </Route>
    </Routes>
  )
}


export { PrivateRoutes }
