import { useEffect, useState, useRef } from 'react'
import { useFormik } from 'formik'

import _, { get } from 'lodash'
import { Collapse } from 'reactstrap'


import { imageToUrl } from '../../../_metronic/helpers'

const FromProgramTV = (props) => {
  const { value, onSubmit } = props
  const ref = useRef(null)
  const [imagePreview, setImagePreview] = useState('')

  const formik = useFormik({
    initialValues: {
      title: get(value, 'title', ''),
      time: get(value, 'time', ''),
      description: get(value, 'description', ''),
      logo_image: get(value, 'logo_image', ''),
      file: null
    },
    validate: (value) => {
      let errors = {};

      if (!Boolean(value.title))
        errors.title = 'Please enter a Title.'

      if (!Boolean(value.time))
        errors.time = 'Please enter a Time slot.'

      if (!Boolean(value.description))
        errors.description = 'Please enter a Description.'

      return errors
    },
    onSubmit: async (value) => {
      return onSubmit(value)
    },
  });

  const onChangeInput = () => {
    const file = get(ref, 'current.files[0]', null)
    if (!Boolean(file)) {
      ref.current.value = null
      setImagePreview('')
      formik.setFieldValue('file', null)
      return null
    }

    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      const img = new Image();
      img.src = reader.result;
      img.onload = () => {
        setImagePreview(reader.result)
        formik.setFieldValue('file', ...ref.current.files)
      }
    }
  }

  useEffect(() => {
    if (!_.isEmpty(value)) {
      setImagePreview(get(value, 'logo_image', ''))
    }
  }, [value])

  return <div className="card card-xxl-stretch mb-5 mb-xl-8">
    <div className='card-header py-6'>
      <div className='card-title w-100 d-flex align-items-center justify-content-between'>
        <div>
          <h4 className='card-label fw-bold fs-3 mb-1'>Setting Program TV</h4>
          <span className='text-muted mt-1 fw-semibold fs-7'>
            Setting Program TV : {get(value, 'title', '')}
          </span>
        </div>
      </div>
    </div>

    <div className='card-body'>
      <div className="row">
        {/* image  */}
        <div className='col-12 col-xl-4'>
          <div className="mb-4">
            <div className="pt-4 d-flex align-items-center justify-content-center mb-4" style={{ minHeight: 350, background: '#f5f8fa', borderRadius: "0.475rem" }}>
              {imagePreview && <img src={imageToUrl(imagePreview)} alt="images" className="rounded" style={{ maxHeight: 320 }} />}
            </div>
            <label className="required fw-bold form-label">Cover image</label>
            <input ref={ref} type="file" accept="image/png, image/jpeg" className="form-control form-control-white" onChange={onChangeInput} />
            <Collapse isOpen={Boolean(formik.errors.file)}>
              <div className="pt-2">
                <span className="text-danger fw-light fs-8">** {formik.errors.file}</span>
              </div>
            </Collapse>
          </div>
        </div>

        {/* infomation */}
        <div className='col-12 col-xl-8'>
          <div className="row">
            <div className='col-12'>
              <div className="mb-4">
                <label className="required fw-bold form-label">Title</label>
                <input
                  type="text"
                  name="title"
                  placeholder="Title"
                  className="form-control form-control-white"
                  value={formik.values.title}
                  onChange={(e) => formik.setFieldValue('title', e.target.value)} />
                <Collapse isOpen={Boolean(formik.errors.title)}>
                  <div className="pt-2">
                    <span className="text-danger fw-light fs-8">** {formik.errors.title}</span>
                  </div>
                </Collapse>
              </div>
            </div>

            <div className='col-12 col-xl-12'>
              <div className="mb-4">
                <label className="required fw-bold form-label">Time slot</label>
                <input
                  type="text"
                  name="time"
                  placeholder="Time slot"
                  className="form-control form-control-white"
                  value={formik.values.time || ''}
                  onChange={(e) => formik.setFieldValue('time', e.target.value)} />
                <Collapse isOpen={Boolean(formik.errors.time)}>
                  <div className="pt-2">
                    <span className="text-danger fw-light fs-8">** {formik.errors.time}</span>
                  </div>
                </Collapse>
              </div>
            </div>

            {/* Description */}
            <div className='col-12'>
              <div className="mb-4">
                <label className="required fw-bold form-label">Description</label>
                <textarea
                  rows={4}
                  type="text"
                  name="description"
                  placeholder="Description"
                  className="form-control form-control-white"
                  value={formik.values.description}
                  onChange={(e) => formik.setFieldValue('description', e.target.value)} />
                <Collapse isOpen={Boolean(formik.errors.description)}>
                  <div className="pt-2">
                    <span className="text-danger fw-light fs-8">** {formik.errors.description}</span>
                  </div>
                </Collapse>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className="card-footer">
      <div className='gap-5 d-flex align-items-center justify-content-end'>
        <button type="submit" className="btn btn-sm btn-primary" onClick={() => formik.handleSubmit()}>
          Save
        </button>
      </div>
    </div>
  </div>
}

export default FromProgramTV