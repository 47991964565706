import { useFormik } from 'formik'
import _, { get } from 'lodash'
import { Collapse } from 'reactstrap'
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { callapiFile } from '../../modules/auth/core/_requestContent'

import { useEffect, useState, useRef } from 'react'



import { imageToUrl } from '../../../_metronic/helpers'


const FromAboutUs = (props) => {
  const ref = useRef(null)
  const [imagePreview, setImagePreview] = useState('')

  const formik = useFormik({
    initialValues: {
      title: get(props, 'title', ''),
      content_detail: get(props, 'content_detail', ''),
      logo_image: get(props, 'logo_image', ''),
    },
    validate: (value) => {
      let errors = {};
      if (!Boolean(value.title))
        errors.title = 'Please enter a Title.'

      if (!Boolean(value.content_detail))
        errors.title = 'Please enter detail.'

      return errors
    },
    onSubmit: (value) => {
      return props.onSubmit(value)
    },
  });

  const uploadAdapter = (loader) => {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          loader.file.then((file) => {
            callapiFile(file)
              .then((res) => resolve({ default: get(res, 'data.data.image_url', '') }))
              .catch(reject)
          })
        })
      }
    }
  }

  function uploadPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return uploadAdapter(loader);
    };
  }

  const onChangeInput = () => {
    const file = get(ref, 'current.files[0]', null)
    if (!Boolean(file)) {
      ref.current.value = null
      setImagePreview('')
      formik.setFieldValue('file', null)
      return null
    }

    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      const img = new Image();
      img.src = reader.result;
      img.onload = () => {
        setImagePreview(reader.result)
        formik.setFieldValue('file', ...ref.current.files)
      }
    }
  }

  useEffect(() => {
    if (!_.isEmpty(props)) {
      setImagePreview(get(props, 'logo_image', ''))
    }
  }, [props])

  return <div className="card card-xxl-stretch mb-5 mb-xl-8">
    <div className='card-header py-6'>
      <div className='card-title w-100 d-flex align-items-center justify-content-between'>
        <div>
          <h4 className='card-label fw-bold fs-3 mb-1'>About Us</h4>
          <span className='text-muted mt-1 fw-semibold fs-7'>
            About Us : Honekrasae
          </span>
        </div>
      </div>
    </div>

    <div className='card-body'>
      <div className="row">
        <div className='col-12 col-xl-4'>
          <div className="mb-4">
            <label className="required fw-bold form-label">Title</label>
            <input
              type="text"
              name="title"
              placeholder="title"
              className="form-control form-control-white"
              value={formik.values.title}
              onChange={(e) => formik.setFieldValue('title', e.target.value)} />
            <Collapse isOpen={Boolean(formik.errors.title)}>
              <div className="pt-2">
                <span className="text-danger fw-light fs-8">** {formik.errors.title}</span>
              </div>
            </Collapse>
          </div>

          <div className="mb-4">
            <div className="pt-4 d-flex align-items-center justify-content-center mb-4" style={{ minHeight: 450, background: '#f5f8fa', borderRadius: "0.475rem" }}>
              {imagePreview && <img src={imageToUrl(imagePreview)} alt="images" className="rounded" style={{ width: "80%" }} />}
            </div>
            <label className="required fw-bold form-label">Image</label>
            <input ref={ref} type="file" accept="image/png, image/jpeg" className="form-control form-control-white" onChange={onChangeInput} />
            <Collapse isOpen={Boolean(formik.errors.file)}>
              <div className="pt-2">
                <span className="text-danger fw-light fs-8">** {formik.errors.file}</span>
              </div>
            </Collapse>
          </div>
        </div>

        <div className='col-12 col-xl-8'>
          <div className="mb-4">
            <label className="required fw-bold form-label">Content detail</label>
            <CKEditor
              editor={ClassicEditor}
              data={formik.values.content_detail}
              config={{ extraPlugins: [uploadPlugin] }}
              className="form-control form-control-white"
              onChange={(_, editor) => {
                const value = editor.getData()
                formik.setFieldValue('content_detail', value)
              }} />
            <Collapse isOpen={Boolean(formik.errors.content_detail)}>
              <div className="pt-2">
                <span className="text-danger fw-light fs-8">** {formik.errors.content_detail}</span>
              </div>
            </Collapse>
          </div>
        </div>
      </div>
    </div>

    <div className="card-footer">
      <div className='gap-5 d-flex align-items-center justify-content-end'>
        <button type="submit" className="btn btn-sm btn-primary" onClick={() => formik.handleSubmit()}>
          Save
        </button>
      </div>
    </div>
  </div>
}

export default FromAboutUs