import { get } from 'lodash'
import { useFormik } from 'formik'
import { Collapse } from 'reactstrap'

const FromContent = (props) => {
  const content_vdo = ["youtube", "facebook"]

  const onCancel = get(props, 'onCancel', () => { })
  const onSubmit = get(props, 'onSubmit', () => { })
  
  const type = get(props, 'type', 'create')
  const value = get(props, 'value', {})

  const date = new Date()
  const _now = Math.floor(date.getTime() / 1000);
  const _now_1Hr = Math.floor(date.setHours(date.getHours() + 1) / 1000);

  function TimestamptoUTC(dateString) {
    const date = new Date(dateString);
    return Math.floor(date.getTime() / 1000);
  }

  function UTCtoTimestamp(timestamp) {
    const date = new Date(timestamp * 1000);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    return `${year}-${month}-${day}T${hours}:${minutes}`;
  }

  const formik = useFormik({
    initialValues: {
      id: get(value, 'id', ''),
      title: get(value, 'title', ''),
      link_url: get(value, 'link_url', ''),
      active: get(value, 'active', true),
      public_date: UTCtoTimestamp(get(value, 'public_date', _now)),
      end_date: UTCtoTimestamp(get(value, 'end_date', _now_1Hr)),
      live_type: get(value, 'live_type', "youtube"),
    },
    validate: (value) => {
      let errors = {};
      if (!Boolean(value.title))
        errors.title = 'Please enter a Live title.'

      if (!Boolean(value.link_url))
        errors.link_url = 'Please enter a Live Link url.'

      return errors
    },
    onSubmit: (value) => {
      return onSubmit(Object.assign({}, value, {
        public_date: TimestamptoUTC(value.public_date),
        end_date: TimestamptoUTC(value.end_date),
      }))
    },
  });

  const onClickContentVDOType = (value) => formik.setFieldValue('live_type', value)

  return <div className="card card-xxl-stretch mb-5 mb-xl-8">
    <div className='card-header py-6'>
      <div className='card-title w-100 d-flex align-items-center justify-content-between'>
        <div>
          <h4 className='card-label fw-bold fs-3 mb-1'>{type === 'create' ? 'Create Live' : 'Edit Live'}</h4>
          <span className='text-muted mt-1 fw-semibold fs-7'>
            Create Live with platform Facebook or Youtube
          </span>
        </div>
      </div>
    </div>

    <div className='card-body'>
      <div className="row">
        <div className="mb-4">
          <label className="required fw-bold form-label">Title</label>
          <input
            type="text"
            name="title"
            placeholder="title"
            className="form-control form-control-white"
            value={formik.values.title}
            onChange={(e) => formik.setFieldValue('title', e.target.value)} />
          <Collapse isOpen={Boolean(formik.errors.title)}>
            <div className="pt-2">
              <span className="text-danger fw-light fs-8">** {formik.errors.title}</span>
            </div>
          </Collapse>
        </div>

        {/* Public Date */}
        <div className='col-12 col-xl-6'>
          <div className="mb-4">
            <label className="required fw-bold form-label">Public Date</label>
            <input
              type="datetime-local"
              name="public_date"
              className="form-control form-control-white"
              value={formik.values.public_date}
              onChange={(e) => {
                formik.setFieldValue('public_date', e.target.value)
                formik.setFieldValue('end_date', e.target.value)
              }} />
          </div>
        </div>
        <div className='col-12 col-xl-6'>
          <div className="mb-4">
            <label className="required sfw-bold form-label">End Date</label>
            <input
              type="datetime-local"
              name="end_date"
              className="form-control form-control-white"
              min={formik.values.public_date}
              value={formik.values.end_date}
              onChange={(e) => formik.setFieldValue('end_date', e.target.value)} />
          </div>
        </div>

        <div className="separator border-2 my-4"></div>
        <div className="mb-4">
          <label className="required fw-bold form-label">Link URL</label>
          <input
            type="text"
            name="title"
            placeholder="link url"
            className="form-control form-control-white"
            value={formik.values.link_url}
            onChange={(e) => formik.setFieldValue('link_url', e.target.value)} />
          <Collapse isOpen={Boolean(formik.errors.link_url)}>
            <div className="pt-2">
              <span className="text-danger fw-light fs-8">** {formik.errors.link_url}</span>
            </div>
          </Collapse>
        </div>

        <div className="mb-4">
          <label className="required fw-bold form-label">Live Type</label>
          <div>
            <div className="d-inline-block">
              {Array.from(content_vdo || []).map((value, k) => {
                const _checked = formik.values.live_type === value
                return <div key={k} className='btn border-0 btn-sm fs-7 px-4 py-1 mb-2' style={{ 'cursor': "auto" }} onClick={() => onClickContentVDOType(value)}>
                  <div className="pointer form-check form-check-custom form-check-solid form-check-sm">
                    <input className="form-check-input" type="checkbox" checked={_checked} onChange={() => onClickContentVDOType(value)} />
                    <label className="form-check-label" onClick={() => onClickContentVDOType(value)}>
                      {value.toLocaleUpperCase()}
                    </label>
                  </div>
                </div>
              })}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className="card-footer">
      <div className='gap-5 d-flex align-items-center justify-content-end'>
        <button className="btn btn-sm btn-light-primary" onClick={onCancel}>Back</button>
        <button type="submit" className="btn btn-sm btn-primary" onClick={() => formik.handleSubmit()}>
          {type === 'create' ? 'Create' : 'Save'}
        </button>
      </div>
    </div>
  </div>
}

export default FromContent