import axios from 'axios'

import { header } from './_header'
import { REQUEST_CMS_LIVE } from './_endpoints'

// API content
export async function apiGetLive() {
  const headers = await header()
  return axios({ headers, method: 'GET', url: `${REQUEST_CMS_LIVE}` })
}

export async function apiGetLiveByid(id) {
  const headers = await header()
  return axios({ headers, method: 'GET', url: `${REQUEST_CMS_LIVE}/${id}` })
}

export async function apiPostLive(body) {
  const headers = await header()
  return axios({ headers, method: 'POST', url: `${REQUEST_CMS_LIVE}`, data: body })
}

export async function apiPutLive(slug, body) {
  const headers = await header()
  return axios({ headers, method: 'PUT', url: `${REQUEST_CMS_LIVE}/${slug}`, data: body })
}

export async function apiPatchLive(slug, action) {
  const headers = await header()
  return axios({ headers, method: 'PATCH', url: `${REQUEST_CMS_LIVE}/${slug}`, params: { active: action } })
}

export async function apiDeleteLive(slug) {
  const headers = await header()
  return axios({ headers, method: 'DELETE', url: `${REQUEST_CMS_LIVE}/${slug}` })
}
