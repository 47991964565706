
import { useState, useEffect } from 'react'
import { get, isEmpty } from 'lodash'
import { useNavigate, useParams } from 'react-router-dom'

import FromContent from './FromContent'

import { apiGetAdsByid, apiPutAds } from '../../modules/auth/core/_requestAds'

const EditWrapper = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [content, setContent] = useState({})

  const enableSplashScreen = () => {
    const splashScreen = document.getElementById('splash-screen')
    if (splashScreen) splashScreen.style.setProperty('display', 'flex')
  }

  const disableSplashScreen = () => {
    const splashScreen = document.getElementById('splash-screen')
    if (splashScreen) splashScreen.style.setProperty('display', 'none')
  }

  const onSubmit = (value) => {
    enableSplashScreen()
    apiPutAds(id, value)
      .then(console.log)
      .catch(console.error)
      .finally(() => navigate('/ads'))
  }

  const getContents = (id) => {
    enableSplashScreen()
    apiGetAdsByid(id)
      .then((res) => setContent(get(res, 'data.data', {})))
      .catch(console.error)
      .finally(() => setTimeout(() => { disableSplashScreen() }, 200))
  }

  useEffect(() => {
    getContents(id)
    // eslint-disable-next-line
  }, [id])

  return (
    <div className="container" style={{ minHeight: '90vh' }}>
      {!isEmpty(content) && <FromContent type="edit" value={content} onSubmit={onSubmit} onCancel={() => navigate('/ads')} />}
    </div>
  )
}

export default EditWrapper