import axios from 'axios'

import { header, headerMultipart } from './_header'
import { REQUEST_CMS_BANNER, REQUEST_CMS_BANNER_IMAGE } from './_endpoints'

// API content
export async function apiGetBanner(banner_type = '') {
  const headers = await header()
  return axios({ headers, method: 'GET', url: `${REQUEST_CMS_BANNER}?banner_type=${banner_type}` })
}

export async function apiGetBannerConverPageByid(id) {
  const headers = await header()
  return axios({ headers, method: 'GET', url: `${REQUEST_CMS_BANNER}/${id}` })
}

export async function apiPostBanner(body) {
  const headers = await header()
  return axios({ headers, method: 'POST', url: `${REQUEST_CMS_BANNER}`, data: body })
}

export async function apiPostImageBanner(id, file) {
  const headers = await headerMultipart()
  const formData = new FormData();
  formData.append('image', file);
  return axios({ headers, method: 'POST', url: `${REQUEST_CMS_BANNER_IMAGE}/${id}`, data: formData })
}

export async function apiPutBanner(slug, body) {
  const headers = await header()
  return axios({ headers, method: 'PUT', url: `${REQUEST_CMS_BANNER}/${slug}`, data: body })
}

export async function apiPatchBanner(slug, action) {
  const headers = await header()
  return axios({ headers, method: 'PATCH', url: `${REQUEST_CMS_BANNER}/${slug}`, params: { active: action } })
}

export async function apiDeleteBanner(slug) {
  const headers = await header()
  return axios({ headers, method: 'DELETE', url: `${REQUEST_CMS_BANNER}/${slug}` })
}
