export const PAGE_LIST = [
  {
    key: "home",
    title: "หน้าหลัก",
  },
  {
    key: "content_detail",
    title: "หน้ารายละเอียดข่าว",
  },
  {
    key: "content_listing",
    title: "หน้ารวมข่าว",
  },
]

export const POSITION_LIST = [
  {
    key: "top",
    title: "ด้านบน",
  },
  {
    key: "center",
    title: "ตรงกลาง",
  },
  {
    key: "botton",
    title: "ด้านล่าง",
  },
]