import axios from 'axios'

import { header } from './_header'
import { REQUEST_CMS_PAGE } from './_endpoints'

// API page
export async function apiGetPageBySlug(page_id) {
  const headers = await header()
  return axios({ headers, method: 'GET', url: `${REQUEST_CMS_PAGE}?id=${page_id}` })
}

export async function apiCreateOrUpdatePage(page_id, body) {
  const headers = await header()
  return axios({ headers, method: 'POST', url: `${REQUEST_CMS_PAGE}?id=${page_id}`, data: body })
}
