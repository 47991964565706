import { useEffect, useState, useRef } from 'react'

import { get } from 'lodash'
import Cookies from 'js-cookie'
import { jwtDecode } from "jwt-decode"

import { URL_CMS_URL } from '../../modules/auth/core/_endpoints'
import { USER_TOKEN_KEY } from '../../modules/auth/core/_cookie.js'
import { apiGetUser, apiCreateUser, apiDeleteUser } from '../../modules/auth/core/_requestUser'

const UserWrapper = (props) => {
  const _token = jwtDecode(Cookies.get(USER_TOKEN_KEY))

  const refs = useRef(null)
  const [isCopy, setCopy] = useState(false)
  const [content, setContent] = useState([])

  const enableSplashScreen = () => {
    const splashScreen = document.getElementById('splash-screen')
    if (splashScreen) splashScreen.style.setProperty('display', 'flex')
  }

  const disableSplashScreen = () => {
    const splashScreen = document.getElementById('splash-screen')
    if (splashScreen) splashScreen.style.setProperty('display', 'none')
  }

  const getUser = () => {
    enableSplashScreen()
    apiGetUser()
      .then((res) => {
        setContent(get(res, 'data.data', []) || [])
      })
      .catch(console.error)
      .finally(() => setTimeout(() => { disableSplashScreen() }, 200))
  }

  const onClickCreateUser = async () => {
    const { link } = await apiCreateUser()
      .then((res) => ({ link: get(res, "data.data.id", "") }))
      .catch(() => ({ link: "" }))

    refs.current.value = `${URL_CMS_URL}/auth/google/${link}`
    var textField = document.createElement('textarea')
    textField.innerText = link
    document.body.appendChild(textField)
    textField.select()
    document.execCommand('copy')
    textField.remove()
  }

  const onClickCopy = () => {
    refs.current.select()
    document.execCommand('copy')
    setCopy(true)
  }

  const onCloseModal = () => {
    refs.current.value = ''
    setCopy(false)
  }

  const onClickDelete = (id) => {
    enableSplashScreen()
    apiDeleteUser(id)
      .then(console.log)
      .catch(console.error)
      .finally(() => getUser())
  }

  useEffect(() => {
    getUser()
    // eslint-disable-next-line
  }, [])

  return <div className="container" style={{ minHeight: '90vh' }}>
    <div className="card card-xxl-stretch mb-5 mb-xl-8">
      <div className='card-header py-6 align-items-center'>
        <div className='card-title align-items-start flex-column'>
          <h4 className='card-label fw-bold fs-3 mb-1'>User</h4>
          <span className='text-muted mt-1 fw-semibold fs-7'>User in Honekrasae</span>
        </div>
        <button className="btn btn-sm btn-primary" data-bs-toggle="modal" data-bs-target="#modal-create-user" onClick={onClickCreateUser}>
          Create user
        </button>
      </div>
      <div className='card-body py-3'>
        <div className='table-responsive'>
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            <thead>
              <tr className='fw-bold text-muted'>
                <th></th>
                <th className='text-center'></th>
                <th className='min-w-500px w-100'>User</th>
                <th className='text-center'></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {Array.from(content).map((value) => {
                return <ItemsRows {...props} value={value} key={value?.id} token={_token} onClickDelete={onClickDelete} />
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
    {/* modal */}
    <div className="modal fade" tabIndex={-1} id="modal-create-user">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className='modal-header'>
            <p className='fw-bold mb-0'>Create new user</p>
          </div>
          <div className="modal-body">
            <p className='text-muted text-center fw-semibold mb-4'>Copy link to create a new user.</p>
            <div className='d-flex gap-5'>
              <input ref={refs} type="text" className="form-control form-control-white" />
              <button type="button" className="btn btn-sm btn-light-info" onClick={onClickCopy}>
                {isCopy ? "copied" : "copy"}
              </button>
            </div>
          </div>
          <div className="modal-footer p-3">
            <button type="button" className="btn btn-sm btn-primary" data-bs-dismiss="modal" onClick={onCloseModal}>
              Done
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
}

const ItemsRows = (props) => {
  const token = get(props, 'token', {})
  const value = get(props, 'value', {})
  const onClickDelete = get(props, 'onClickDelete', () => { })

  return <tr key={value?.id} className={token?.user?.id === value?.id ? "bg-light-success" : ""}>
    <td></td>
    <td>
      <div className="symbol symbol-50px">
        <div className="symbol-label fs-2 fw-bold">
          {String(value?.name || value?.id || "-")[0].toLocaleUpperCase()}
        </div>
      </div>
    </td>
    <td>
      <div className='d-flex justify-content-start flex-column'>
        {/* <span className='fs-8 text-gray-700'>{`ID : ${value?.id}`}</span> */}
        <h4 className='text-dark fw-bold fs-6 one-line-ellipsis'>{value.name}</h4>
        <span className='fs-6 text-gray-700'>{value?.email}</span>
      </div>
    </td>
    <td className='align-top text-center'>
      <div className='gap-1 d-flex justify-content-center flex-shrink-0'>
        {/* Remove */}
        {
          token?.user?.roles === 'admin'
          && value?.roles !== "admin"
          && token?.user?.email !== value?.email
          && <div className='gap-1 d-flex flex-column align-items-center'>
            <button className='btn btn-icon btn-bg-light btn-sm me-1' data-bs-toggle="modal" data-bs-target={`#modal-${value?.id}`}>
              <i className="fa-sharp fa-solid fa-trash"></i>
            </button>
            <span className='fs-8 text-gray-700'>Remove</span>
          </div>
        }
      </div>

      {/* modal */}
      <div className="modal fade" tabIndex={-1} id={`modal-${value?.id}`}>
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body mt-6 min-h-150px d-flex align-items-center justify-content-center">
              <div className="text-center">
                <p className='text-muted fw-semibold mb-0'>You want to Remove User</p>
                <p className='text-dark fw-bold fs-6 mb-0'> "{value?.name}" </p>
              </div>
            </div>
            <div className="modal-footer p-3">
              <button type="button" className="btn btn-sm btn-light" data-bs-dismiss="modal"> Cancal</button>
              <button type="button" className="btn btn-sm btn-light-danger" data-bs-dismiss="modal" onClick={() => onClickDelete(value?.id)}>
                Remove
              </button>
            </div>
          </div>
        </div>
      </div>
    </td>
    <td></td>
  </tr>
}

export default UserWrapper