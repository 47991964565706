import axios from 'axios'

import { header } from './_header'
import { REQUEST_CMS_HELPME } from './_endpoints'

export async function apiGetHelpme({ page = 1, limit = 10 }) {
  const headers = await header()
  return axios({ headers, method: 'GET', url: `${REQUEST_CMS_HELPME}?page=${page}&limit=${limit}` })
}

export async function apiRemoveHelpme(id) {
  const headers = await header()
  return axios({ headers, method: 'DELETE', url: `${REQUEST_CMS_HELPME}/${id}` })
}