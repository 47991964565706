import { useNavigate } from 'react-router-dom'
import { apiPostLive } from '../../modules/auth/core/_requestLive'

import FromBanner from './FromContent'

const CreateLiveWrapper = () => {
  const navigate = useNavigate();
  const enableSplashScreen = () => {
    const splashScreen = document.getElementById('splash-screen')
    if (splashScreen) splashScreen.style.setProperty('display', 'flex')
  }

  const onSubmit = (value) => {
    const data = {
      active: true,
      title: value.title,
      link_url: value.link_url,
      public_date: value.public_date,
      end_date: value.end_date,
      live_type: value.live_type,
    }

    enableSplashScreen()
    apiPostLive(data)
      .then(console.log)
      .catch(console.error)
      .finally(() => navigate('/live'))
  }

  return (
    <div className="container" style={{ minHeight: '90vh' }}>
      <FromBanner type="create" onSubmit={onSubmit} onClickOntrend={() => { }} onCancel={() => navigate('/live')} />
    </div>
  )
}

export default CreateLiveWrapper
