import { useEffect, useState, useRef } from 'react'
import _, { get } from 'lodash'
import { useFormik } from 'formik'
import { Collapse } from 'reactstrap'

import { imageToUrl } from '../../../_metronic/helpers'

const FromContent = (props) => {
  const [isChangeImage, setChangeImage] = useState(false)
  const [imagePreview, setImagePreview] = useState('')

  const onCancel = get(props, 'onCancel', () => { })
  const onSubmit = get(props, 'onSubmit', () => { })

  const ref = useRef(null)
  const type = get(props, 'type', 'create')
  const value = get(props, 'value', {})

  const date = new Date()
  const _now = Math.floor(date.getTime() / 1000);
  const _now_1day = Math.floor(date.setDate(date.getDate() + 1) / 1000);

  function TimestamptoUTC(dateString) {
    const date = new Date(dateString);
    return Math.floor(date.getTime() / 1000);
  }

  function UTCtoTimestamp(timestamp) {
    const date = new Date(timestamp * 1000);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    return `${year}-${month}-${day}T${hours}:${minutes}`;
  }

  useEffect(() => {
    if (!_.isEmpty(value)) {
      setImagePreview(get(value, 'image', ''))
    }
  }, [value])

  const formik = useFormik({
    initialValues: {
      id: get(value, 'id', ''),
      title: get(value, 'title', ''),
      link_url: get(value, 'link_url', ''),
      active: get(value, 'active', true),
      public_date: UTCtoTimestamp(get(value, 'public_date', _now)),
      end_date: UTCtoTimestamp(get(value, 'end_date', _now_1day)),
      file: null,
    },
    validate: (value) => {
      let errors = {};
      if (!Boolean(value.title))
        errors.title = 'Please enter a Content title.'

      if (!Boolean(value.link_url))
        errors.link_url = 'Please enter a Content Link url.'

      if (type === "create" || isChangeImage) {
        if (!Boolean(value.file))
          errors.file = 'Please enter a banner image'
      }
      return errors
    },
    onSubmit: (value) => {
      return onSubmit(Object.assign({}, value, {
        public_date: TimestamptoUTC(value.public_date),
        end_date: TimestamptoUTC(value.end_date),
      }))
    },
  });

  const onChangeInput = () => {
    setChangeImage(true)
    const file = get(ref, 'current.files[0]', null)
    if (!Boolean(file)) {
      ref.current.value = null
      setImagePreview('')
      formik.setFieldValue('file', null)
      return null
    }

    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      const img = new Image();
      img.src = reader.result;
      img.onload = () => {
        setImagePreview(reader.result)
        formik.setFieldValue('file', ...ref.current.files)

        // const width = get(img, 'width', 0)
        // const height = get(img, 'height', 0)
        // if (width === 1200 && height === 630) {
        //   setImagePreview(reader.result)
        //   formik.setFieldValue('file', ...ref.current.files)
        // } else {
        //   setImagePreview('')
        //   ref.current.value = null
        //   formik.setFieldValue('file', null)
        //   formik.setErrors({ file: 'Please enter Image size 1200x630' })
        // }
      }
    }
  }

  return <div className="card card-xxl-stretch mb-5 mb-xl-8">
    <div className='card-header py-6'>
      <div className='card-title w-100 d-flex align-items-center justify-content-between'>
        <div>
          <h4 className='card-label fw-bold fs-3 mb-1'>{type === 'create' ? 'Create Banner' : 'Edit Banner'}</h4>
          <span className='text-muted mt-1 fw-semibold fs-7'>
            {type === 'create' ? 'Create banner' : 'Edit banner'} : Cover page
          </span>
        </div>
      </div>
    </div>

    <div className='card-body'>
      <div className="row">
        <div className="mb-4">
          <label className="required fw-bold form-label">Title</label>
          <input
            type="text"
            name="title"
            placeholder="title"
            className="form-control form-control-white"
            value={formik.values.title}
            onChange={(e) => formik.setFieldValue('title', e.target.value)} />
          <Collapse isOpen={Boolean(formik.errors.title)}>
            <div className="pt-2">
              <span className="text-danger fw-light fs-8">** {formik.errors.title}</span>
            </div>
          </Collapse>
        </div>

        <div className="mb-4">
          <label className="required fw-bold form-label">Link URL</label>
          <input
            type="text"
            name="title"
            placeholder="link url or path"
            className="form-control form-control-white"
            value={formik.values.link_url}
            onChange={(e) => formik.setFieldValue('link_url', e.target.value)} />
          <Collapse isOpen={Boolean(formik.errors.link_url)}>
            <div className="pt-2">
              <span className="text-danger fw-light fs-8">** {formik.errors.link_url}</span>
            </div>
          </Collapse>
        </div>

        {/* Public Date */}
        <div className='col-12 col-xl-6'>
          <div className="mb-4">
            <label className="required fw-bold form-label">Public Date</label>
            <input
              type="datetime-local"
              name="public_date"
              className="form-control form-control-white"
              value={formik.values.public_date}
              onChange={(e) => {
                formik.setFieldValue('public_date', e.target.value)
                formik.setFieldValue('end_date', e.target.value)
              }} />
          </div>
        </div>
        <div className='col-12 col-xl-6'>
          <div className="mb-4">
            <label className="required sfw-bold form-label">End Date</label>
            <input
              type="datetime-local"
              name="end_date"
              className="form-control form-control-white"
              min={formik.values.public_date}
              value={formik.values.end_date}
              onChange={(e) => formik.setFieldValue('end_date', e.target.value)} />
          </div>
        </div>

        <div className="mb-4">
          <div className="d-flex align-items-center justify-content-between">
            <label className="required fw-bold form-label">Banner Cover image</label>
            {/* <span className="text-gray-400 mt-1 fw-semibold fs-7">1200x630 (witdh x height)</span> */}
          </div>
          <input ref={ref} type="file" accept="image/png, image/jpeg" className="form-control form-control-white" onChange={onChangeInput} />
          <Collapse isOpen={Boolean(formik.errors.file)}>
            <div className="pt-2">
              <span className="text-danger fw-light fs-8">** {formik.errors.file}</span>
            </div>
          </Collapse>
          <div className="pt-4 d-flex align-items-center justify-content-center">
            {imagePreview && <img src={imageToUrl(imagePreview)} alt="images" className="rounded" style={{ width: '100%', maxWidth: 520 / 2 }} />}
          </div>
        </div>
      </div>
    </div>

    <div className="card-footer">
      <div className='gap-5 d-flex align-items-center justify-content-end'>
        <button className="btn btn-sm btn-light-primary" onClick={onCancel}>Back</button>
        <button type="submit" className="btn btn-sm btn-primary" onClick={() => formik.handleSubmit()}>
          {type === 'create' ? 'Create' : 'Save'}
        </button>
      </div>
    </div>
  </div>
}

export default FromContent