import { toAbsoluteUrl } from '../../../../helpers'
import { SidebarMenuItem } from './SidebarMenuItem'

const SidebarMenuMain = () => {
  return (
    <>
      {/* MENU */}
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>MENU</span>
        </div>
      </div>
      <SidebarMenuItem to='/content' icon={toAbsoluteUrl('/media/icons/duotune/general/gen005.svg')} title='Content' fontIcon='bi-layers' />
      <SidebarMenuItem to='/live' icon={toAbsoluteUrl('/media/icons/duotune/general/gen014.svg')} title='Live' fontIcon='bi-layers' />
      <SidebarMenuItem to='/banner/cover_page' icon={toAbsoluteUrl('/media/icons/duotune/general/gen006.svg')} title='Banner' fontIcon='bi-layers' />
      <SidebarMenuItem to='/ads' icon={toAbsoluteUrl('/media/icons/duotune/general/gen017.svg')} title='Ads' fontIcon='bi-layers' />

      {/* PROGRAM TV */}
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>PROGRAM TV</span>
        </div>
      </div>
      <SidebarMenuItem to='/program/honekrasae-news' icon={toAbsoluteUrl('/media/icons/duotune/communication/com007.svg')} title='โหนกระแส' fontIcon='bi-layers' />
      <SidebarMenuItem to='/program/honekrasae-morning' icon={toAbsoluteUrl('/media/icons/duotune/general/gen060.svg')} title='โหนกระแสแต่เช้า' fontIcon='bi-layers' />
      <SidebarMenuItem to='/help' icon={toAbsoluteUrl('/media/icons/duotune/technology/teh007.svg')} title='โหนร้องทุกข์' fontIcon='bi-layers' />

      {/* PAGE */}
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>PAGE</span>
        </div>
      </div>
      <SidebarMenuItem to='/page/about-us' icon={toAbsoluteUrl('/media/icons/duotune/general/gen003.svg')} title='About Us' fontIcon='bi-layers' />
      <SidebarMenuItem to='/page/term-and-conditions' icon={toAbsoluteUrl('/media/icons/duotune/communication/com009.svg')} title='Term and Condition' fontIcon='bi-layers' />

      {/* CONFIG */}
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>CONFIG & USER</span>
        </div>
      </div>
      <SidebarMenuItem to='/config' icon={toAbsoluteUrl('/media/icons/duotune/coding/cod001.svg')} title='Config' fontIcon='bi-layers' />
      <SidebarMenuItem to='/user' icon={toAbsoluteUrl('/media/icons/duotune/communication/com014.svg')} title='User' fontIcon='bi-layers' />
    </>
  )
}

export { SidebarMenuMain }
