import { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { get, isEmpty } from 'lodash'
import { apiGetBannerConverPageByid, apiPutBanner, apiPostImageBanner } from '../../modules/auth/core/_requestBanner'

import FromContent from './FromContent'

const EditBannerCoverPageWrapper = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [content, setContent] = useState({})

  const enableSplashScreen = () => {
    const splashScreen = document.getElementById('splash-screen')
    if (splashScreen) splashScreen.style.setProperty('display', 'flex')
  }

  const disableSplashScreen = () => {
    const splashScreen = document.getElementById('splash-screen')
    if (splashScreen) splashScreen.style.setProperty('display', 'none')
  }

  const onSubmit = (value) => {
    const data = {
      banner_type: "cover-page",
      title: value.title,
      link_url: value.link_url,
      public_date: value.public_date,
      end_date: value.end_date,
      image: value.image,
      active: value.active,
    }

    enableSplashScreen()
    apiPutBanner(id, data)
      .then(() => {
        if (Boolean(value.file))
          return apiPostImageBanner(id, value.file)
        return new Promise((resolve, _) => resolve())
      })
      .then(console.log)
      .catch(console.error)
      .finally(() => navigate('/banner/cover_page'))
  }

  const getContents = (id) => {
    enableSplashScreen()
    apiGetBannerConverPageByid(id)
      .then((res) => setContent(get(res, 'data.data', {})))
      .catch(console.error)
      .finally(() => setTimeout(() => { disableSplashScreen() }, 200))
  }

  useEffect(() => {
    getContents(id)
    // eslint-disable-next-line
  }, [id])

  return (
    <div className="container" style={{ minHeight: '90vh' }}>
      {!isEmpty(content) && <FromContent type="edit" value={content} onSubmit={onSubmit} onCancel={() => navigate('/banner/cover_page')} />}
    </div>
  )
}

export default EditBannerCoverPageWrapper

