import axios from 'axios'

import { header } from './_header'
import { REQUEST_CMS_TAGS } from './_endpoints'

// API content
export async function apiGetTags() {
  const headers = await header()
  return axios({ headers, method: 'GET', url: `${REQUEST_CMS_TAGS}` })
}
