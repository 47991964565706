import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { get } from 'lodash'
import Cookies from 'js-cookie'

import { PAGE_LIST, POSITION_LIST } from './data'

import { USER_TOKEN_KEY } from '../../modules/auth/core/_cookie'
import { apiGetAds, apiDeleteAds } from '../../modules/auth/core/_requestAds'

const AdsWrapper = (props) => {
  const navigate = useNavigate();
  const [content, setContent] = useState([])

  const enableSplashScreen = () => {
    const splashScreen = document.getElementById('splash-screen')
    if (splashScreen) splashScreen.style.setProperty('display', 'flex')
  }

  const disableSplashScreen = () => {
    const splashScreen = document.getElementById('splash-screen')
    if (splashScreen) splashScreen.style.setProperty('display', 'none')
  }

  const getAds = () => {
    enableSplashScreen()
    apiGetAds()
      .then((res) => {
        setContent(get(res, 'data.data', []) || [])
      })
      .catch((error) => {
        if (String(error?.response?.data?.message || "").includes("Token")) {
          enableSplashScreen()
          Cookies.remove(USER_TOKEN_KEY)
          setTimeout(() => {
            window.location.reload()
          }, 500)
        }
      })
      .finally(() => setTimeout(() => { disableSplashScreen() }, 200))
  }

  const onClickDelete = (id) => {
    enableSplashScreen()
    apiDeleteAds(id)
      .then(console.log)
      .catch(console.error)
      .finally(() => getAds())
  }

  useEffect(() => {
    getAds()
    // eslint-disable-next-line
  }, [])

  return <div className="container" style={{ minHeight: '90vh' }}>
    <div className="card card-xxl-stretch mb-5 mb-xl-8">
      <div className='card-header py-6 align-items-center'>
        <div className='card-title align-items-start flex-column'>
          <h4 className='card-label fw-bold fs-3 mb-1'>Ads</h4>
          <span className='text-muted mt-1 fw-semibold fs-7'>Ads on Honekrasae</span>
        </div>
        <button className="btn btn-sm btn-primary" onClick={() => navigate('/ads/create')}>Create Ads</button>
      </div>
      <div className='card-body py-3'>
        <div className='table-responsive'>
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            <thead>
              <tr className='fw-bold text-muted'>
                <th></th>
                <th className='min-w-500px w-100'>Title</th>
                <th className='min-w-100px text-center'>Page</th>
                <th className='min-w-100px text-center'>Position</th>
                <th className='min-w-200px text-center'>Create Date</th>
                <th className='text-center'>Actions</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {Array.from(content).map((value) => {
                return <ItemsRows {...props} value={value} key={value?.id} onClickDelete={onClickDelete} />
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
}

const ItemsRows = (props) => {
  const navigate = useNavigate();
  const value = get(props, 'value', {})

  const onClickDelete = get(props, 'onClickDelete', () => { })
  const onClickEdit = () => navigate(`/ads/edit/${value?.id}`)

  const TimestamptoDate = (time) => {
    const option = { year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric' }
    return new Date(time * 1000).toLocaleDateString('th-TH', option)
  }

  return <tr key={value?.id}>
    <td></td>
    <td className='align-top'>
      <h4 className='text-dark fw-bold fs-6 one-line-ellipsis'>{value.title}</h4>
      {/* <hr style={{ borderTopColor: "#b8b8b8" }} />
      <code>{value?.script}</code> */}
    </td>
    <td className='align-top text-center'>
      <span className="badge badge-light-primary">
        {PAGE_LIST.find(v => v.key === value?.page).title}
      </span>
    </td>
    <td className='align-top text-center'>
      <span className="badge badge-light-success">
        {POSITION_LIST.find(v => v.key === value?.position).title}
      </span>
    </td>
    <td className='align-top text-center'>
      <p className='text-dark fw-light fs-7 m-0 one-line-ellipsis'>{TimestamptoDate(value?.create_date)} น.</p>
    </td>
    <td className='align-top text-center'>
      <div className='gap-1 d-flex justify-content-end flex-shrink-0'>
        {/* Edit */}
        <div className='gap-1 d-flex flex-column align-items-center' onClick={() => onClickEdit(value?.id)}>
          <button className='btn btn-icon btn-bg-light btn-sm me-1'>
            <i className="fa-solid fa-pencil"></i>
          </button>
          <span className='fs-8 text-gray-700'>Edit</span>
        </div>
        {/* Remove */}
        <div className='gap-1 d-flex flex-column align-items-center'>
          <button className='btn btn-icon btn-bg-light btn-sm me-1' data-bs-toggle="modal" data-bs-target={`#modal-${value?.id}`}>
            <i className="fa-sharp fa-solid fa-trash"></i>
          </button>
          <span className='fs-8 text-gray-700'>Remove</span>
        </div>
      </div>

      {/* modal */}
      <div className="modal fade" tabIndex={-1} id={`modal-${value?.id}`}>
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body mt-6 min-h-150px d-flex align-items-center justify-content-center">
              <div className="text-center">
                <p className='text-muted fw-semibold mb-0'>You want to Remove content</p>
                <p className='text-dark fw-bold fs-6 mb-0'> "{value?.title}" </p>
              </div>
            </div>
            <div className="modal-footer p-3">
              <button type="button" className="btn btn-sm btn-light" data-bs-dismiss="modal"> Cancal</button>
              <button type="button" className="btn btn-sm btn-light-danger" data-bs-dismiss="modal" onClick={() => onClickDelete(value?.id)}>
                Remove
              </button>
            </div>
          </div>
        </div>
      </div>
    </td>
    <td></td>
  </tr>
}

export default AdsWrapper