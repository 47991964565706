import { useState } from 'react'
import { jwtDecode } from "jwt-decode";
import { Collapse } from 'reactstrap'

import { apiLogin } from "../MyAuth";
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';

export const Login = () => {
  const [error, setError] = useState({})
  const requestLogin = ({ email }) => {
    setError({})
    apiLogin({ email })
      .then(() => window.location.reload())
      .catch((error) => setError(error))
  }

  const errorMessage = (error) => console.log("error", error);
  const responseMessage = (response) => {
    const decoded = jwtDecode(response.credential);
    requestLogin({ email: decoded?.email })
  }

  return (
    <div className="form w-100" id="kt_login_signin_form">
      <div className="text-center mb-8">
        <h1 className="text-dark fw-bolder mb-3">Welcome to Honekrasae</h1>
        <div className="text-gray-500 fw-semibold fs-4">
          Back office service
        </div>
      </div>

      <div className="d-grid mb-10 gap-4">
        <div className="d-grid justify-content-center">
          <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CID}>
            <GoogleLogin
              size="large"
              locale="th_TH"
              text="continue_with"
              theme="filled_blue"
              onError={errorMessage}
              onSuccess={responseMessage}
            />
          </GoogleOAuthProvider>
        </div>
        <Collapse isOpen={Boolean(error?.message)}>
          <div className='text-center'>
            <span className='text-center fw-bold text-danger'>{error?.message}</span>
          </div>
        </Collapse>
      </div>
    </div>
  );
};
