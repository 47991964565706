import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { get } from 'lodash'
import Cookies from 'js-cookie'

import { imageToUrl } from '../../../_metronic/helpers'
import { USER_TOKEN_KEY } from '../../modules/auth/core/_cookie'
import { apiGetBanner, apiPatchBanner, apiDeleteBanner } from '../../modules/auth/core/_requestBanner'

const TYPE = "cover-page"
const BannerCoverPageWrapper = (props) => {
  const navigate = useNavigate();
  const [content, setContent] = useState([])

  const enableSplashScreen = () => {
    const splashScreen = document.getElementById('splash-screen')
    if (splashScreen) splashScreen.style.setProperty('display', 'flex')
  }

  const disableSplashScreen = () => {
    const splashScreen = document.getElementById('splash-screen')
    if (splashScreen) splashScreen.style.setProperty('display', 'none')
  }

  const getBanner = () => {
    enableSplashScreen()
    apiGetBanner(TYPE)
      .then((res) => {
        setContent(get(res, 'data.data', []) || [])
      })
      .catch((error) => {
        if (String(error?.response?.data?.message || "").includes("Token")) {
          enableSplashScreen()
          Cookies.remove(USER_TOKEN_KEY)
          setTimeout(() => {
            window.location.reload()
          }, 500)
        }
      })
      .finally(() => setTimeout(() => { disableSplashScreen() }, 200))
  }

  const onClickShow = (id, action) => {
    enableSplashScreen()
    apiPatchBanner(id, action)
      .then(console.log)
      .catch(console.error)
      .finally(() => getBanner())
  }

  const onClickDelete = (id) => {
    enableSplashScreen()
    apiDeleteBanner(id)
      .then(console.log)
      .catch(console.error)
      .finally(() => getBanner())
  }

  useEffect(() => {
    getBanner()
    // eslint-disable-next-line
  }, [])

  return (
    <div className="container" style={{ minHeight: '90vh' }}>
      <div className="card card-xxl-stretch mb-5 mb-xl-8">
        <div className='card-header py-6 align-items-center'>
          <div className='card-title align-items-start flex-column'>
            <h4 className='card-label fw-bold fs-3 mb-1'>Banner</h4>
            <span className='text-muted mt-1 fw-semibold fs-7'>Banner cover page</span>
          </div>
          <button className="btn btn-sm btn-primary" onClick={() => navigate('/banner/cover_page/create')}>Create Banner</button>
        </div>
        <div className='card-body py-3'>
          <div className='table-responsive'>
            <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
              <thead>
                <tr className='fw-bold text-muted'>
                  <th></th>
                  <th className="w-100px"></th>
                  <th className='min-w-500px w-100'>Title</th>
                  <th className='min-w-200px text-center'>Start date</th>
                  <th className='min-w-200px text-center'>End date</th>
                  <th className='min-w-150px text-center'>Actions</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {Array.from(content).map((value) => {
                  return <ItemsRows
                    {...props}
                    value={value}
                    key={value?.id}
                    onClickShow={onClickShow}
                    onClickDelete={onClickDelete} />
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

const ItemsRows = (props) => {
  const navigate = useNavigate();
  const value = get(props, 'value', {})

  const onClickShow = get(props, 'onClickShow', () => { })
  const onClickDelete = get(props, 'onClickDelete', () => { })
  const onClickEdit = () => navigate(`/banner/cover_page/edit/${value?.id}`)

  const TimestamptoDate = (time) => {
    const option = { year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric' }
    return new Date(time * 1000).toLocaleDateString('th-TH', option)
  }

  return <tr className={`${value.active ? "" : "bg-secondary"}`} key={value?.id}>
    <td></td>
    <td className='align-top text-center'>
      <div className="w-125px">
        {value?.image && <img className="w-125px" alt={value?.title} src={imageToUrl(value?.image)} />}
      </div>
    </td>
    <td className='align-top'>
      <div className='d-flex justify-content-start flex-column'>
        <h4 className='text-dark fw-bold fs-6 one-line-ellipsis mb-2'>{value?.title}</h4>
        <div className='d-flex align-items-center gap-2 mb-0 w-50'>
          <h4 className='text-dark fw-bold fs-6 one-line-ellipsis m-0'>Link url : </h4>
          <span className='fs-6 text-gray-700'>{value?.link_url}</span>
        </div>
      </div>
    </td>
    <td className='align-top text-center'>
      <p className='text-dark fw-light fs-7 m-0 one-line-ellipsis'>{TimestamptoDate(value?.public_date)} น.</p>
    </td>
    <td className='align-top text-center'>
      <p className='text-dark fw-light fs-7 m-0 one-line-ellipsis'>{TimestamptoDate(value?.end_date)} น.</p>
    </td>
    <td className='align-top text-center'>
      <div className='gap-1 d-flex justify-content-end flex-shrink-0'>
        {/* Show */}
        <div className='gap-1 d-flex flex-column align-items-center'>
          <button href='#' className='btn btn-icon btn-bg-light btn-sm me-1' onClick={() => onClickShow(value?.id, !value?.active)}>
            {value?.active ? <i className="fa-regular fa-eye-slash"></i> : <i className="fa-regular fa-eye"></i>}
          </button>
          <span className='fs-8 text-gray-700'>{value?.active ? "Hide" : "Show"}</span>
        </div>
        {/* Edit */}
        <div className='gap-1 d-flex flex-column align-items-center' onClick={() => onClickEdit(value?.id)}>
          <button className='btn btn-icon btn-bg-light btn-sm me-1'>
            <i className="fa-solid fa-pencil"></i>
          </button>
          <span className='fs-8 text-gray-700'>Edit</span>
        </div>
        {/* Remove */}
        <div className='gap-1 d-flex flex-column align-items-center'>
          <button className='btn btn-icon btn-bg-light btn-sm me-1' data-bs-toggle="modal" data-bs-target={`#modal-${value?.id}`}>
            <i className="fa-sharp fa-solid fa-trash"></i>
          </button>
          <span className='fs-8 text-gray-700'>Remove</span>
        </div>
      </div>

      {/* modal */}
      <div className="modal fade" tabIndex={-1} id={`modal-${value?.id}`}>
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body mt-6 min-h-150px d-flex align-items-center justify-content-center">
              <div className="text-center">
                <p className='text-muted fw-semibold mb-0'>You want to Remove content</p>
                <p className='text-dark fw-bold fs-6 mb-0'> "{value?.title}" </p>
              </div>
            </div>
            <div className="modal-footer p-3">
              <button type="button" className="btn btn-sm btn-light" data-bs-dismiss="modal"> Cancal</button>
              <button type="button" className="btn btn-sm btn-light-danger" data-bs-dismiss="modal" onClick={() => onClickDelete(value?.id)}>
                Remove
              </button>
            </div>
          </div>
        </div>
      </div>
    </td>
    <td></td>
  </tr>
}

export default BannerCoverPageWrapper
