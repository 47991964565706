import { useEffect, useState } from 'react'
import { get } from 'lodash'
import Cookies from 'js-cookie'

import { USER_TOKEN_KEY } from '../../modules/auth/core/_cookie'
import { apiGetHelpme, apiRemoveHelpme } from '../../modules/auth/core/_requestHelp'

const LIMIT = 50
const HelpmeWrapper = (props) => {
  const [page, setPage] = useState(1)
  const [total, setTotal] = useState(0)
  const [content, setContent] = useState([])

  const enableSplashScreen = () => {
    const splashScreen = document.getElementById('splash-screen')
    if (splashScreen) splashScreen.style.setProperty('display', 'flex')
  }

  const disableSplashScreen = () => {
    const splashScreen = document.getElementById('splash-screen')
    if (splashScreen) splashScreen.style.setProperty('display', 'none')
  }

  const getContents = ({ page }) => {
    enableSplashScreen()
    apiGetHelpme({ page, limit: LIMIT })
      .then((res) => {
        setPage(get(res, 'data.data.page', 1))
        setTotal(get(res, 'data.data.total', 1))
        setContent(get(res, 'data.data.help_me', []) || [])
      })
      .catch((error) => {
        if (String(error?.response?.data?.message || "").includes("Token")) {
          enableSplashScreen()
          Cookies.remove(USER_TOKEN_KEY)
          setTimeout(() => {
            window.location.reload()
          }, 500)
        }
      })
      .finally(() => setTimeout(() => { disableSplashScreen() }, 200))
  }

  const onClickDelete = (id) => {
    enableSplashScreen()
    apiRemoveHelpme(id)
      .then(console.log)
      .catch(console.error)
      .finally(() => getContents({ page: page }))
  }

  useEffect(() => {
    getContents({ page: 1 })
    // eslint-disable-next-line
  }, [])

  return (
    <div className="container" style={{ minHeight: '90vh' }}>
      <div className="card card-xxl-stretch mb-4">
        <div className='card-header py-6 align-items-center'>
          <div className='card-title align-items-start flex-column'>
            <h4 className='card-label fw-bold fs-3 mb-1'>โหนร้องทุกข์</h4>
            <span className='text-muted mt-1 fw-semibold fs-7'>รายละเอียดโหนร้องทุกข์</span>
          </div>
        </div>
      </div>
      {Array.from(content).map((value, key) => {
        return <div className="card card-xxl-stretch mb-4">
          <div className='card-body py-3'>
            <div className='table-responsive'>
              <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                <thead>
                  <tr className='fw-bold text-muted' >
                    <th></th>
                    <th style={{ width: '75%' }}></th>
                    <th className='w-200px text-center'></th>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {Array.from(content).map((value, _key) => {
                    return key === _key && <ItemsRows {...props} value={value} key={value?.id} onClickDelete={onClickDelete} />
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      })}
      <div className='gap-5 d-flex align-items-center justify-content-end'>
        <ul className="pagination">
          {Array(Math.ceil(total / LIMIT)).fill('').map((_, key) => {
            const _page = key + 1
            return <li key={key} className={_page === page ? "page-item active" : "page-item"} >
              <p className="page-link m-0" onClick={() => getContents({ page: _page, limit: LIMIT })}>{_page}</p>
            </li>
          })}
        </ul>
      </div>
    </div>
  )
}

const ItemsRows = (props) => {
  const value = get(props, 'value', {})
  const onClickDelete = get(props, 'onClickDelete', () => { })

  const TimestamptoDate = (time) => {
    const option = { year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric' }
    return new Date(time * 1000).toLocaleDateString('th-TH', option)
  }

  return <>
    <tr key={`a-${value?.id}`} style={{ background: props.run % 2 === -1 && '#b8b8b833' }}>
      <td></td>
      <td className='align-top'>
        <div className='d-flex justify-content-start flex-column'>
          <div className='d-flex align-items-center gap-2 mb-2'>
            <h4 className='text-dark fw-bold fs-6 one-line-ellipsis m-0'>Name : </h4>
            <span className='fs-6 text-gray-700'>{value?.name}</span>
          </div>
          <div className='d-flex align-items-center gap-2 mb-2'>
            <h4 className='text-dark fw-bold fs-6 one-line-ellipsis m-0'>Email : </h4>
            <span className='fs-6 text-gray-700'>{value?.email}</span>
          </div>
          <div className='d-flex align-items-center gap-2 mb-2'>
            <h4 className='text-dark fw-bold fs-6 one-line-ellipsis m-0'>Phone : </h4>
            <span className='fs-6 text-gray-700'>{value?.phone}</span>
          </div>
        </div>
      </td>
      <td className='align-center text-center'>
        <p className='text-dark fw-light fs-7 m-0 one-line-ellipsis'>{TimestamptoDate(value?.create_date)} น.</p>
      </td>
      <td className='align-top text-center'>
        <div className='gap-1 d-flex justify-content-center flex-shrink-0'>
          {/* Remove */}
          <div className='gap-1 d-flex flex-column align-items-center'>
            <button className='btn btn-icon btn-bg-light btn-sm me-1' data-bs-toggle="modal" data-bs-target={`#modal-${value?.id}`}>
              <i className="fa-sharp fa-solid fa-trash"></i>
            </button>
            <span className='fs-8 text-gray-700'>Remove</span>
          </div>
        </div>

        {/* modal */}
        <div className="modal fade" tabIndex={-1} id={`modal-${value?.id}`}>
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body mt-6 min-h-150px d-flex align-items-center justify-content-center">
                <div className="text-center">
                  <p className='text-muted fw-semibold mb-0'>You want to Remove</p>
                </div>
              </div>
              <div className="modal-footer p-3">
                <button type="button" className="btn btn-sm btn-light" data-bs-dismiss="modal"> Cancal</button>
                <button type="button" className="btn btn-sm btn-light-danger" data-bs-dismiss="modal" onClick={() => onClickDelete(value?.id)}>
                  Remove
                </button>
              </div>
            </div>
          </div>
        </div>
      </td>
      <td></td>
    </tr>
    <tr key={`b-${value?.id}`} style={{ background: props.run % 2 === -1 && '#b8b8b833' }}>
      <td></td>
      <td className='align-top' colSpan={3}>
        <div className='d-flex justify-content-start flex-column'>
          <div className='d-flex align-items-center gap-2 mb-0'>
            <div className='fs-6 text-gray-700' dangerouslySetInnerHTML={{ __html: String(value?.detail).replaceAll('\n', '<br />') }}></div>
          </div>
        </div>
      </td>
      <td></td>
    </tr>
  </>
}

export default HelpmeWrapper
