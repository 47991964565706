import axios from 'axios'

import { header } from './_header'
import { REQUEST_CMS_CONFIG } from './_endpoints'

// API content
export async function apiGetConfig(id) {
  const headers = await header()
  return axios({ headers, method: 'GET', url: `${REQUEST_CMS_CONFIG}?id=${id}` })
}

export async function apiCreateOrUpdateConfig(id, body) {
  const headers = await header()
  return axios({ headers, method: 'POST', url: `${REQUEST_CMS_CONFIG}?id=${id}`, data: body })
}
