import { useState } from "react";
import { jwtDecode } from "jwt-decode";
import { useParams, useNavigate } from 'react-router-dom'
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';

import { apiUpdaetUser } from '../../modules/auth/core/_requestUser'
import { isEmpty } from "lodash";

const Google = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [user, setUser] = useState({})
  const [isError, setError] = useState({})

  const errorMessage = (error) => console.log("error", error);
  const responseMessage = (response) => {
    if (!Boolean(response?.credential))
      return

    const decoded = jwtDecode(response.credential);
    const payload = {
      "id": id,
      "email": decoded?.email,
      "name": decoded?.name,
      "image": "",
      "roles": "user",
      "verify": true
    }

    apiUpdaetUser(payload)
      .then(() => setUser(decoded))
      .catch((error) => setError(error))
  };

  return <div className="form w-100" id="kt_login_signin_form">
    <div className="text-center mb-8">
      <h1 className="text-dark fw-bolder mb-3">Welcome to Honekrasae</h1>
      {
        !isEmpty(isError)
          ? <div className="text-danger fw-semibold fs-6">Create user failed</div>
          : !isEmpty(user)
            ? <div className="text-success fw-semibold fs-6">Create user success</div>
            : <div className="text-gray-500 fw-semibold fs-6">Create new user with Google</div>
      }
    </div>

    {
      !isEmpty(isError)
        ? <div>
          <div className="d-flex align-items-center bg-light-danger rounded p-5 mb-4">
            <div className="flex-grow-1">
              <p className="fw-bold text-hover-danger text-danger fs-6 m-0">ERROR</p>
              <span className="text-danger fw-light d-block">message: {isError?.response?.data?.message || "Error something went wrong"} </span>
            </div>
            <div className="symbol symbol-50px">
              <div className="symbol-label fs-2 fw-bold bg-danger text-inverse-danger">
                <i className="text-light fs-2x bi bi-exclamation-circle-fill"></i>
              </div>
            </div>
          </div>
          <button type="submit" id="kt_sign_in_submit" className="btn btn-primary w-100" onClick={() => navigate('/')}>
            <span className="indicator-label">Go to Back</span>
          </button>
        </div>
        : !isEmpty(user)
          ? <div>
            <div className="d-flex align-items-center bg-light-success rounded p-5 mb-4">
              <div className="symbol symbol-50px me-4">
                <div className="symbol-label fs-2 fw-bold">
                  {String(user?.name || user?.id || "-")[0].toLocaleUpperCase()}
                </div>
              </div>
              <div className="flex-grow-1 me-2">
                <p className="fw-bold text-gray-800 text-hover-info fs-6 m-0">{user?.name}</p>
                <span className="text-muted fw-light d-block">{user?.email}</span>
              </div>
              <div className="symbol symbol-50px">
                <div className="symbol-label fs-2 fw-bold bg-success text-inverse-danger">
                  <i className="text-light fs-2x bi bi-check-circle-fill"></i>
                </div>
              </div>
            </div>
            <button type="submit" id="kt_sign_in_submit" className="btn btn-primary w-100" onClick={() => navigate('/')}>
              <span className="indicator-label">Go to Login</span>
            </button>
          </div>
          : <div className="d-grid justify-content-center">
            <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CID}>
              <GoogleLogin
                size="large"
                locale="th_TH"
                text="continue_with"
                theme="filled_blue"
                onError={errorMessage}
                onSuccess={responseMessage}
              />
            </GoogleOAuthProvider>
          </div>
    }
  </div>
}

export default Google