import Cookies from "js-cookie";
import { get } from "lodash";
// import { apiPostAuth } from "../../modules/auth/core/_requestAuth";

import { USER_TOKEN_KEY } from "./core/_cookie";
import { apiUserLogin } from "./core/_requestUser";

const useMyAuth = () => {
  const _token = Cookies.get(USER_TOKEN_KEY);
  return { currentUser: Boolean(_token) };
};

const apiLogin = (value) => {
  return new Promise(async (resovle, reject) => {
    apiUserLogin(value)
      .then(async (res) => {
        const data = get(res, 'data.data.token', '')
        await Cookies.set(USER_TOKEN_KEY, data, { expires: 0.8 })
        return resovle(data)
      })
      .catch(() => {
        return reject({ message: 'Please try again with Google email verification.' })
      })
  });
};

export { useMyAuth, apiLogin };
