import { useEffect, useState, useRef } from 'react'
import _, { get, size } from 'lodash'
import { useFormik } from 'formik'
import { Collapse } from 'reactstrap'

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import { imageToUrl } from '../../../_metronic/helpers'
import { apiGetTags } from '../../modules/auth/core/_requestTags'
import { callapiFile } from '../../modules/auth/core/_requestContent'

const FromContent = (props) => {
  const [tags, setTags] = useState([])
  const [isCode, setCode] = useState(false)
  const [inputHashtag, setInputHash] = useState('')
  const [isChangeImage, setChangeImage] = useState(false)
  const [imagePreview, setImagePreview] = useState('')

  const content_vdo = ["youtube", "facebook", "tiktok"]
  const group = ["ข่าวกำลังโหน", "โหนทุกข่าว", "โหนบันเทิง", "โหนไปมู", "โหนกระแส", "โหนกระแสแต่เช้า"]
  const category = ["ข่าวด่วน", "ข่าวโซเชียล", "ดราม่า", "ภูมิภาค", "อาชญากรรม", "การเมือง", "บันเทิง", "หวย", "สายมู"]

  const onCancel = get(props, 'onCancel', () => { })
  const onSubmit = get(props, 'onSubmit', () => { })
  const onClickOntrend = get(props, 'onClickOntrend', () => { })

  const ref = useRef(null)
  const type = get(props, 'type', 'create')
  const value = get(props, 'value', {})

  const _now = Math.floor(new Date().getTime() / 1000);

  function TimestamptoUTC(dateString) {
    const date = new Date(dateString);
    return Math.floor(date.getTime() / 1000);
  }

  function UTCtoTimestamp(timestamp) {
    const date = new Date(timestamp * 1000);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    return `${year}-${month}-${day}T${hours}:${minutes}`;
  }

  const getTags = () => {
    apiGetTags()
      .then((res) => setTags(get(res, 'data.data', {})))
      .catch(console.error)
  }

  useEffect(() => {
    getTags()
  }, [])

  useEffect(() => {
    if (!_.isEmpty(value)) {
      setImagePreview(get(value, 'image', ''))
    }
  }, [value])

  const formik = useFormik({
    initialValues: {
      id: get(value, 'id', ''),
      title: get(value, 'title', ''),
      short_description: get(value, 'short_description', ''),
      content_detail: get(value, 'content_detail', ''),
      group: get(value, 'group', []) || [],
      category: get(value, 'category', []) || [],
      public_date: UTCtoTimestamp(get(value, 'public_date', _now)),
      hashtag: get(value, 'hashtag', []) || [],
      content_vdo: get(value, 'content_vdo', "") || "",
      content_vdo_type: get(value, 'content_vdo_type', "") || "",
      file: null,
    },
    validate: (value) => {
      let errors = {};
      if (!Boolean(value.title))
        errors.title = 'Please enter a Content title.'

      if (!Boolean(value.short_description))
        errors.short_description = 'Please enter a Content Short description.'

      if (!Boolean(value.content_detail))
        errors.content_detail = 'Please enter a Content detail.'

      if (type === "create" || isChangeImage) {
        if (!Boolean(value.file))
          errors.file = 'Please enter a Conver image size 1200x630'
      }

      if (!Boolean(size(value.group)))
        errors.group = 'Please select a Group.'

      if (!Boolean(size(value.category)))
        errors.category = 'Please select a Category.'

      if (Boolean(value.content_vdo) || Boolean(value.content_vdo_type)) {
        if (!Boolean(value.content_vdo && value.content_vdo_type)) {
          errors.content_vdo = "Please enter content VDO"
        }
      }
      return errors
    },
    onSubmit: (value) => {
      return onSubmit(Object.assign({}, value, {
        hashtag: value.hashtag.map(v => v && String(v).trim()),
        public_date: TimestamptoUTC(value.public_date)
      }))
    },
  });

  const addHashtag = (value = "") => {
    value = String(value).trim()
    if (value === "")
      return

    let arr = formik.values.hashtag
    formik.setFieldValue('hashtag', [...arr, value])
    setInputHash("")
  }

  const deleteHashtag = (index) => {
    let arr = formik.values.hashtag
    arr = Array.from(arr).filter((_, i) => i !== index)
    formik.setFieldValue('hashtag', arr)
  }

  const onClickGroup = (value) => {
    let arr = formik.values.group
    if (_.indexOf(formik.values.group, value) === -1) arr = [...arr, value]
    else arr = arr.filter(f => f !== value)
    formik.setFieldValue('group', arr)
  }

  const onClickCategory = (value) => {
    let arr = formik.values.category
    if (_.indexOf(formik.values.category, value) === -1) arr = [...arr, value]
    else arr = arr.filter(f => f !== value)
    formik.setFieldValue('category', arr)
  }

  const onClickContentVDOType = (value) => {
    formik.setFieldValue('content_vdo_type', formik.values.content_vdo_type === value ? "" : value)
  }

  const uploadAdapter = (loader) => {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          loader.file.then((file) => {
            callapiFile(file)
              .then((res) => resolve({ default: get(res, 'data.data.image_url', '') }))
              .catch(reject)
          })
        })
      }
    }
  }

  function uploadPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return uploadAdapter(loader);
    };
  }

  const onChangeInput = () => {
    setChangeImage(true)
    const file = get(ref, 'current.files[0]', null)
    if (!Boolean(file)) {
      ref.current.value = null
      setImagePreview('')
      formik.setFieldValue('file', null)
      return null
    }

    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      const img = new Image();
      img.src = reader.result;
      img.onload = () => {
        const width = get(img, 'width', 0)
        const height = get(img, 'height', 0)
        if (width === 1200 && height === 630) {
          setImagePreview(reader.result)
          formik.setFieldValue('file', ...ref.current.files)
        } else {
          setImagePreview('')
          ref.current.value = null
          formik.setFieldValue('file', null)
          formik.setErrors({ file: 'Please enter Image size 1200x630' })
        }
      }
    }
  }



  return <div className="card card-xxl-stretch mb-5 mb-xl-8">
    <div className='card-header py-6'>
      <div className='card-title w-100 d-flex align-items-center justify-content-between'>
        <div>
          <h4 className='card-label fw-bold fs-3 mb-1'>{type === 'create' ? 'Create Content' : 'Edit Content'}</h4>
          <span className='text-muted mt-1 fw-semibold fs-7'>
            {type === 'create' ? 'Create Content' : 'Edit Content'} About : News
          </span>
        </div>
        {type === 'edit' && <>
          <div className="form-check form-switch form-check-custom form-check-solid" >
            <input className="form-check-input h-25px w-45px" type="checkbox" value="" id="flexSwitch30x50" checked={value?.on_trend ? "checked" : ""}
              onClick={() => onClickOntrend(value?.id, !value?.on_trend)}
            />
            <label className="form-check-label fs-6" for="flexSwitch30x50">
              Trending News
            </label>
          </div>
        </>}
      </div>
    </div>

    <div className='card-body'>
      <div className="mb-4">
        <label className="required fw-bold form-label">Title</label>
        <input
          type="text"
          name="title"
          placeholder="title"
          className="form-control form-control-white"
          value={formik.values.title}
          onChange={(e) => formik.setFieldValue('title', e.target.value)} />
        <Collapse isOpen={Boolean(formik.errors.title)}>
          <div className="pt-2">
            <span className="text-danger fw-light fs-8">** {formik.errors.title}</span>
          </div>
        </Collapse>
      </div>

      <div className="mb-4">
        <label className="required fw-bold form-label">Short description</label>
        <textarea
          rows={3}
          type="textarea"
          className="form-control form-control-white"
          placeholder="Short description"
          value={formik.values.short_description}
          onChange={(e) => formik.setFieldValue('short_description', e.target.value)} />
        <Collapse isOpen={Boolean(formik.errors.short_description)}>
          <div className="pt-2">
            <span className="text-danger fw-light fs-8">** {formik.errors.short_description}</span>
          </div>
        </Collapse>
      </div>

      <div className="mb-4">
        <div className='w-100 mb-2 d-flex align-items-center justify-content-between'>
          <label className="required fw-bold form-label">Content detail</label>
          <code style={{ fontSize: 10, cursor: "pointer" }} onClick={() => setCode(!isCode)}>
            {isCode ? `Editor` : `<Source />`}
          </code>
        </div>
        <Collapse isOpen={!isCode}>
          <CKEditor
            editor={ClassicEditor}
            data={formik.values.content_detail}
            config={{ extraPlugins: [uploadPlugin] }}
            className="form-control form-control-white"
            onChange={(_, editor) => {
              const value = editor.getData()
              formik.setFieldValue('content_detail', value)
            }} />
        </Collapse>
        <Collapse isOpen={isCode}>
          <textarea
            rows={6}
            placeholder={`<Source />`}
            className="form-control form-control-white"
            value={formik.values.content_detail}
            onChange={e => formik.setFieldValue('content_detail', e.target.value)} />
        </Collapse>
        <Collapse isOpen={Boolean(formik.errors.content_detail)}>
          <div className="pt-2">
            <span className="text-danger fw-light fs-8">** {formik.errors.content_detail}</span>
          </div>
        </Collapse>
      </div>

      <div className="mb-4">
        <div className="d-flex align-items-center justify-content-between">
          <label className="required fw-bold form-label">Cover image</label>
          <span className="text-gray-400 mt-1 fw-semibold fs-7">1200x630 (witdh x height)</span>
        </div>
        <input ref={ref} type="file" accept="image/png, image/jpeg" className="form-control form-control-white" onChange={onChangeInput} />
        <Collapse isOpen={Boolean(formik.errors.file)}>
          <div className="pt-2">
            <span className="text-danger fw-light fs-8">** {formik.errors.file}</span>
          </div>
        </Collapse>
        <div className="pt-4 d-flex align-items-center justify-content-center">
          {imagePreview && <img src={imageToUrl(imagePreview)} alt="images" className="rounded" style={{ width: '100%', maxWidth: 520 / 2 }} />}
        </div>
      </div>

      <div className="mb-4">
        <label className="fw-bold form-label">Public Date</label>
        <input
          type="datetime-local"
          name="public_date"
          className="form-control form-control-white"
          value={formik.values.public_date}
          onChange={(e) => formik.setFieldValue('public_date', e.target.value)} />
      </div>

      <div className="separator border-2 my-4"></div>
      <div className="mb-4">
        <div className='mb-4'>
          <label className="fw-bold form-label">Content VDO</label>
          <p className="fw-light form-label fs-6 me-4"> Link url (Facebook, Youtube) / Embed (Tiktok)</p>
        </div>
        <div>
          <div className="d-inline-block">
            {Array.from(content_vdo || []).map((value, k) => {
              const _checked = formik.values.content_vdo_type === value
              return <div key={k} className='btn border-0 btn-sm fs-7 px-4 py-1 mb-2' style={{ 'cursor': "auto" }} onClick={() => onClickContentVDOType(value)}>
                <div className="pointer form-check form-check-custom form-check-solid form-check-sm">
                  <input className="form-check-input" type="checkbox" checked={_checked} onChange={() => onClickContentVDOType(value)} />
                  <label className="form-check-label" onClick={() => onClickContentVDOType(value)}>
                    {value.toLocaleUpperCase()}
                  </label>
                </div>
              </div>
            })}
          </div>
        </div>
      </div>
      <div className="mb-4">
        <textarea
          type="textarea"
          className="form-control form-control-white"
          rows={formik.values.content_vdo_type === 'tiktok' ? 5 : 3}
          disabled={formik.values.content_vdo_type === ""}
          placeholder={formik.values.content_vdo_type === 'tiktok' ? "Embed (Tiktok)" : "Link url (Facebook, Youtube)"}
          value={formik.values.content_vdo}
          onChange={(e) => formik.setFieldValue('content_vdo', e.target.value)} />
        <Collapse isOpen={Boolean(formik.errors.content_vdo) || Boolean(formik.errors.content_vdo_type)}>
          <div className="pt-2">
            <span className="text-danger fw-light fs-8">** {formik.errors.content_vdo}</span>
          </div>
        </Collapse>
      </div>

      <div className="separator border-2 my-4"></div>
      <div className="mb-4">
        <label className="required fw-bold form-label mb-4">Group content</label>
        <div>
          <div className=" d-inline-block">
            {Array.from(group || []).map((value, k) => {
              const _checked = _.indexOf(formik.values.group, value) > -1
              return <div key={k} className='btn border-0 btn-sm fs-7 px-4 py-1 mb-2' style={{ 'cursor': "auto" }} onClick={() => onClickGroup(value)}>
                <div className="pointer form-check form-check-custom form-check-solid form-check-sm">
                  <input className="form-check-input" type="checkbox" checked={_checked} onChange={() => onClickGroup(value)} />
                  <label className="form-check-label" onClick={() => onClickGroup(value)}>
                    {value}
                  </label>
                </div>
              </div>
            })}
          </div>
        </div>
        <Collapse isOpen={Boolean(formik.errors.group)}>
          <div className="pt-2">
            <span className="text-danger fw-light fs-8">** {formik.errors.group}</span>
          </div>
        </Collapse>
      </div>

      <div className="separator border-2 my-4"></div>
      <div className="mb-4">
        <label className="required fw-bold form-label mb-4">Category</label>
        <div>
          <div className=" d-inline-block">
            {Array.from(category || []).map((value, k) => {
              const _checked = _.indexOf(formik.values.category, value) > -1
              return <div key={k} className='btn border-0 btn-sm fs-7 px-4 py-1 mb-2' style={{ 'cursor': "auto" }} onClick={() => onClickCategory(value)}>
                <div className="pointer form-check form-check-custom form-check-solid form-check-sm">
                  <input className="form-check-input" type="checkbox" checked={_checked} onChange={() => onClickCategory(value)} />
                  <label className="form-check-label" onClick={() => onClickCategory(value)}>
                    {value}
                  </label>
                </div>
              </div>
            })}
          </div>
        </div>
        <Collapse isOpen={Boolean(formik.errors.category)}>
          <div className="pt-2">
            <span className="text-danger fw-light fs-8">** {formik.errors.category}</span>
          </div>
        </Collapse>
      </div>

      <div className="separator border-2 my-4"></div>
      <div className="mb-4">
        <div>
          <label className="fw-bold form-label mb-4">Hashtag</label>
        </div>
        <div className="modal fade" tabIndex={-1} id={'modal-create-hashtag'}>
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <label className="required fw-bold form-label mb-0">Hashtag</label>
              </div>
              <div className="modal-body">
                <span className='fw-bold d-block fs-7'>Search hashtag</span>
                <input
                  list="browsers"
                  name="browser"
                  id="browser"
                  placeholder="hashtag"
                  className="form-control form-control-white mt-4"
                  value={inputHashtag}
                  onChange={(e) => setInputHash(e.target.value)} />
                <datalist id="browsers">
                  {tags.map((v) => <option key={v?.key} value={v?.name} />)}
                </datalist>
                <div className='mt-8'>
                  <span className='fw-bold d-block fs-7'>Sugguestion</span>
                  <div className='d-inline-block mt-4' style={{ height: 140, overflowY: 'auto' }}>
                    {tags.filter((v) => String(v.name).includes(inputHashtag)).map((v, k) => {
                      return <button
                        key={k}
                        className={
                          v.name === inputHashtag
                            ? 'btn btn-primary border btn-sm fs-8 me-2 px-4 py-1 mb-2'
                            : 'btn btn-light text-black border btn-sm fs-8 me-2 px-4 py-1 mb-2'
                        }
                        onClick={() => setInputHash(v.name)}>
                        {v.name}
                      </button>
                    })}
                  </div>
                </div>
              </div>
              <div className="modal-footer ">
                <button type="button" className="btn btn-sm btn-light" data-bs-dismiss="modal"> Cancal</button>
                <button type="button" className={"btn btn-sm btn-primary"} disabled={inputHashtag === ""} data-bs-dismiss="modal" onClick={() => addHashtag(inputHashtag)}>
                  Add
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className='d-inline-block'>
          {Array.from(formik.values.hashtag).map((v, k) => {
            return <button key={k} className='btn btn-light text-black border btn-sm fs-8 me-2 px-4 py-1 mb-2' onClick={() => deleteHashtag(k)}>
              {`#${v}`}
              <i className="bi bi-x fs-4 p-0 ms-2"></i>
            </button>
          })}
          <button className='btn btn-light-primary border btn-sm fs-8 me-2 px-4 py-1 mb-2' data-bs-toggle="modal" data-bs-target={'#modal-create-hashtag'}>
            <i className="bi bi-plus fs-4 me-1"></i>
            Add Hashtag
          </button>
        </div>
      </div>
    </div>

    <div className="card-footer">
      <div className='gap-5 d-flex align-items-center justify-content-end'>
        <button className="btn btn-sm btn-light-primary" onClick={onCancel}>Back</button>
        <button type="submit" className="btn btn-sm btn-primary" onClick={() => formik.handleSubmit()}>
          {type === 'create' ? 'Create' : 'Save'}
        </button>
      </div>
    </div>
  </div>
}

export default FromContent