const API_URL = process.env.REACT_APP_API_URL
const CMS_URL = process.env.REACT_APP_CMS_URL

export const URL_CMS_URL = CMS_URL
export const REQUEST_AUTH = `${API_URL}/auth`

// ---------- new ----------
export const REQUEST_CMS_IMAGE = `${API_URL}/image`
export const REQUEST_CMS_UPLOAD = `${API_URL}/cms/upload`

export const REQUEST_CMS_CONTENT = `${API_URL}/content`
export const REQUEST_CMS_CONTENT_IMAGE = `${API_URL}/content-image`
export const REQUEST_CMS_TAGS = `${API_URL}/tags`

export const REQUEST_CMS_PAGE = `${API_URL}/page`
export const REQUEST_CMS_CONFIG = `${API_URL}/config`

export const REQUEST_CMS_BANNER = `${API_URL}/banner`
export const REQUEST_CMS_BANNER_IMAGE = `${API_URL}/banner-image`

export const REQUEST_CMS_HELPME = `${API_URL}/help_me`
export const REQUEST_CMS_LIVE = `${API_URL}/live`

export const REQUEST_CMS_ADS = `${API_URL}/ads`
export const REQUEST_CMS_USER = `${API_URL}/user`
export const REQUEST_CMS_LOGIN = `${API_URL}/login`