import axios from 'axios'

import { header } from './_header'
import { REQUEST_CMS_ADS } from './_endpoints'

export async function apiGetAds() {
  const headers = await header()
  return axios({ headers, method: 'GET', url: `${REQUEST_CMS_ADS}` })
}

export async function apiGetAdsByid(id) {
  const headers = await header()
  return axios({ headers, method: 'GET', url: `${REQUEST_CMS_ADS}/${id}` })
}

export async function apiPostAds(body) {
  const headers = await header()
  return axios({ headers, method: 'POST', url: `${REQUEST_CMS_ADS}`, data: body })
}

export async function apiPutAds(id, body) {
  const headers = await header()
  return axios({ headers, method: 'PUT', url: `${REQUEST_CMS_ADS}/${id}`, data: body })
} 

export async function apiDeleteAds(id) {
  const headers = await header()
  return axios({ headers, method: 'DELETE', url: `${REQUEST_CMS_ADS}/${id}` })
}
