import axios from 'axios'

import { header, headerMultipart, headerCmsSteam } from './_header'
import { REQUEST_CMS_IMAGE, REQUEST_CMS_CONTENT, REQUEST_CMS_CONTENT_IMAGE } from './_endpoints'
import { REQUEST_CMS_UPLOAD } from './_endpoints'


export const callapiLinkSteam = async (params, body) => {
  const headers = await headerCmsSteam()
  return axios({ headers, method: 'POST', url: `${REQUEST_CMS_UPLOAD}`, params, data: body })
}

// API content
export async function apiGetContent({ page = 1, limit = 10 }) {
  const headers = await header()
  return axios({ headers, method: 'GET', url: `${REQUEST_CMS_CONTENT}?page=${page}&limit=${limit}` })
}

export async function apiPostContent(body) {
  const headers = await header()
  return axios({ headers, method: 'POST', url: `${REQUEST_CMS_CONTENT}`, data: body })
}

export async function apiPutContent(content_slug, body) {
  const headers = await header()
  return axios({ headers, method: 'PUT', url: `${REQUEST_CMS_CONTENT}/${content_slug}`, data: body })
}

export async function apiPatchContent(id, key, action) {
  const headers = await header()
  return axios({ headers, method: 'PATCH', url: `${REQUEST_CMS_CONTENT}/${id}`, params: { key: key, value: action ? "true" : "false" } })
}

export async function apiDeleteContent(id) {
  const headers = await header()
  return axios({ headers, method: 'DELETE', url: `${REQUEST_CMS_CONTENT}/${id}` })
}

export async function apiCreateImageContent(id, file) {
  const headers = await headerMultipart()
  const formData = new FormData();
  formData.append('image', file);
  return axios({ headers, method: 'POST', url: `${REQUEST_CMS_CONTENT_IMAGE}/${id}`, data: formData })
}

export async function apiGetContentBySlug(id) {
  const headers = await header()
  return axios({ headers, method: 'GET', url: `${REQUEST_CMS_CONTENT}/${id}` })
}

export const callapiFile = async (file) => {
  const headers = await headerMultipart()
  const formData = new FormData();
  formData.append('image', file);
  return axios({ headers, method: "POST", url: `${REQUEST_CMS_IMAGE}`, data: formData })
}