import { useFormik } from 'formik'
import { get } from 'lodash'
import { Collapse } from 'reactstrap'
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const FromTermAndConditionsWrapper = (props) => {
  const formik = useFormik({
    initialValues: {
      title: get(props, 'title', ''),
      content_detail: get(props, 'content_detail', ''),
    },
    validate: (value) => {
      let errors = {};
      if (!Boolean(value.title))
        errors.title = 'Please enter a Title.'

      if (!Boolean(value.content_detail))
        errors.title = 'Please enter detail.'

      return errors
    },
    onSubmit: (value) => {
      return props.onSubmit(value)
    },
  });

  return <div className="card card-xxl-stretch mb-5 mb-xl-8">
    <div className='card-header py-6'>
      <div className='card-title w-100 d-flex align-items-center justify-content-between'>
        <div>
          <h4 className='card-label fw-bold fs-3 mb-1'>Term and Condition</h4>
          <span className='text-muted mt-1 fw-semibold fs-7'>
            Term and Condition : Honekrasae
          </span>
        </div>
      </div>
    </div>

    <div className='card-body'>
      <div className="mb-4">
        <label className="required fw-bold form-label">Title</label>
        <input
          type="text"
          name="title"
          placeholder="title"
          className="form-control form-control-white"
          value={formik.values.title}
          onChange={(e) => formik.setFieldValue('title', e.target.value)} />
        <Collapse isOpen={Boolean(formik.errors.title)}>
          <div className="pt-2">
            <span className="text-danger fw-light fs-8">** {formik.errors.title}</span>
          </div>
        </Collapse>
      </div>
      <div className="mb-4">
        <label className="required fw-bold form-label">Content detail</label>
        <CKEditor
          editor={ClassicEditor}
          data={formik.values.content_detail}
          className="form-control form-control-white"
          onChange={(_, editor) => {
            const value = editor.getData()
            formik.setFieldValue('content_detail', value)
          }} />
        <Collapse isOpen={Boolean(formik.errors.content_detail)}>
          <div className="pt-2">
            <span className="text-danger fw-light fs-8">** {formik.errors.content_detail}</span>
          </div>
        </Collapse>
      </div>
    </div>
    <div className="card-footer">
      <div className='gap-5 d-flex align-items-center justify-content-end'>
        <button type="submit" className="btn btn-sm btn-primary" onClick={() => formik.handleSubmit()}>
          Save
        </button>
      </div>
    </div>
  </div>
}

export default FromTermAndConditionsWrapper