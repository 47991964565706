import { useNavigate } from 'react-router-dom'
import { apiPostBanner, apiPostImageBanner } from '../../modules/auth/core/_requestBanner'

import FromBanner from './FromContent'

const CreateBannerCoverPageWrapper = () => {
  const navigate = useNavigate();
  const enableSplashScreen = () => {
    const splashScreen = document.getElementById('splash-screen')
    if (splashScreen) splashScreen.style.setProperty('display', 'flex')
  }

  const onSubmit = (value) => {
    const data = {
      title: value.title,
      link_url: value.link_url,
      public_date: value.public_date,
      end_date: value.end_date,
      banner_type: "cover-page",
      image: "",
      active: true,
    }

    enableSplashScreen()
    apiPostBanner(data)
      .then((res) => apiPostImageBanner(res?.data?.data?.id, value.file))
      .then(console.log)
      .catch(console.error)
      .finally(() => navigate('/banner/cover_page'))
  }

  return (
    <div className="container" style={{ minHeight: '90vh' }}>
      <FromBanner type="create" onSubmit={onSubmit} onClickOntrend={() => { }} onCancel={() => navigate('/banner/cover_page')} />
    </div>
  )
}

export default CreateBannerCoverPageWrapper
