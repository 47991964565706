import { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { get, isEmpty } from 'lodash'
import { apiGetContentBySlug, apiPatchContent, apiCreateImageContent, apiPutContent } from '../../modules/auth/core/_requestContent'

import FromContent from './FromContent'

const EditContentWrapper = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [content, setContent] = useState({})

  const enableSplashScreen = () => {
    const splashScreen = document.getElementById('splash-screen')
    if (splashScreen) splashScreen.style.setProperty('display', 'flex')
  }

  const disableSplashScreen = () => {
    const splashScreen = document.getElementById('splash-screen')
    if (splashScreen) splashScreen.style.setProperty('display', 'none')
  }

  const onSubmit = (value) => {
    const data = {
      title: value.title,
      content_detail: value.content_detail,
      short_description: value.short_description,
      create_date: value.create_date,
      public_date: value.public_date,
      group: value.group,
      hashtag: value.hashtag,
      category: value.category,
      content_vdo: value.content_vdo,
      content_vdo_type: value.content_vdo_type,
    }

    enableSplashScreen()
    apiPutContent(id, data)
      .then(() => {
        if (Boolean(value.file))
          return apiCreateImageContent(id, value.file)
        return new Promise((resolve, _) => resolve())
      })
      .then(console.log)
      .catch(console.error)
      .finally(() => navigate('/content'))
  }

  const onClickOntrend = (id, action) => {
    enableSplashScreen()
    apiPatchContent(id, "on_trend", action)
      .then(console.log)
      .catch(console.error)
      .finally(() => getContents(id))
  }

  const getContents = (id) => {
    enableSplashScreen()
    apiGetContentBySlug(id)
      .then((res) => setContent(get(res, 'data.data', {})))
      .catch(console.error)
      .finally(() => setTimeout(() => { disableSplashScreen() }, 200))
  }

  useEffect(() => {
    getContents(id)
    // eslint-disable-next-line
  }, [id])

  return (
    <div className="container" style={{ minHeight: '90vh' }}>
      {!isEmpty(content) && <FromContent type="edit" value={content} onSubmit={onSubmit} onClickOntrend={onClickOntrend} onCancel={() => navigate('/content')} />}
    </div>
  )
}

export { EditContentWrapper }

