import { Route, Routes } from 'react-router-dom'
import { Login } from './components/Login'
import { AuthLayout } from './AuthLayout'
import Google from '../../pages/Google'
// import { Registration } from './components/Registration'
// import { ForgotPassword } from './components/ForgotPassword'

const AuthPage = () => (
  <Routes>
    <Route element={<AuthLayout />}>
      <Route path='login' element={<Login />} />
      <Route path='google/:id' element={<Google />} />
      <Route index element={<Login />} />
    </Route>
  </Routes>
)

export { AuthPage }
