import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { get, size } from 'lodash'
import Cookies from 'js-cookie'

import { imageToUrl } from '../../../_metronic/helpers'
import { USER_TOKEN_KEY } from '../../modules/auth/core/_cookie'
import { apiGetContent, apiPatchContent, apiDeleteContent } from '../../modules/auth/core/_requestContent'

const LIMIT = 50
const ContentWrapper = (props) => {
  const navigate = useNavigate();
  const [page, setPage] = useState(1)
  const [total, setTotal] = useState(0)
  const [content, setContent] = useState([])

  const enableSplashScreen = () => {
    const splashScreen = document.getElementById('splash-screen')
    if (splashScreen) splashScreen.style.setProperty('display', 'flex')
  }

  const disableSplashScreen = () => {
    const splashScreen = document.getElementById('splash-screen')
    if (splashScreen) splashScreen.style.setProperty('display', 'none')
  }

  const getContents = ({ page, limit }) => {
    enableSplashScreen()
    apiGetContent({ page, limit })
      .then((res) => {
        setPage(get(res, 'data.data.page', 1))
        setTotal(get(res, 'data.data.total', 1))
        setContent(get(res, 'data.data.content', []) || [])
      })
      .catch((error) => {
        if (String(error?.response?.data?.message || "").includes("Token")) {
          enableSplashScreen()
          Cookies.remove(USER_TOKEN_KEY)
          setTimeout(() => {
            window.location.reload()
          }, 500)
        }
      })
      .finally(() => setTimeout(() => { disableSplashScreen() }, 200))
  }

  const onClickShow = (id, action) => {
    enableSplashScreen()
    apiPatchContent(id, "active", action)
      .then(console.log)
      .catch(console.error)
      .finally(() => getContents({ page: page, limit: LIMIT }))
  }

  const onClickOntrend = (id, action) => {
    enableSplashScreen()
    apiPatchContent(id, "on_trend", action)
      .then(console.log)
      .catch(console.error)
      .finally(() => getContents({ page: page, limit: LIMIT }))
  }

  const onClickDelete = (id) => {
    enableSplashScreen()
    apiDeleteContent(id)
      .then(console.log)
      .catch(console.error)
      .finally(() => getContents({ page: 1, limit: LIMIT }))
  }

  const onClickPreview = (id) => {
    window.open(`https://honekrasae.com/content/${id}?force=1`, "_blank")
  }

  function element(id, currentPage, dot) {
    if (dot)
      return <li className="page-item">
        <p className="page-link">...</p>
      </li>

    return <li key={id} className={id === currentPage ? "page-item active" : "page-item"} >
      <p className="page-link" onClick={() => getContents({ page: id, limit: LIMIT })}>{id}</p>
    </li>
  }

  function generatePagination(currentPage, totalPage) {
    let pagination = [];

    if (totalPage <= 1) return <div></div>;

    const range = 3;
    const startPage = Math.max(2, currentPage - range);
    const endPage = Math.min(totalPage - 1, currentPage + range);

    pagination.push(element(1, currentPage, false));
    if (startPage > 2)
      pagination.push(element(0, 0, true));

    for (let i = startPage; i <= endPage; i++) {
      pagination.push(element(i, currentPage, false));
    }

    if (endPage < totalPage - 1)
      pagination.push(element(0, 0, true));

    // totalPage
    pagination.push(element(totalPage, currentPage, false));

    return pagination
  }

  useEffect(() => {
    getContents({ page: 1, limit: LIMIT })
    // eslint-disable-next-line
  }, [])

  return (
    <div className="container" style={{ minHeight: '90vh' }}>
      <div className="card card-xxl-stretch mb-5 mb-xl-8">
        <div className='card-header py-6 align-items-center'>
          <div className='card-title align-items-start flex-column'>
            <h4 className='card-label fw-bold fs-3 mb-1'>Content</h4>
            <span className='text-muted mt-1 fw-semibold fs-7'>Content About : News, Video, Trip and Health.</span>
          </div>
          <button className="btn btn-sm btn-primary" onClick={() => navigate('/content/create')}>Create Content</button>
        </div>
        <div className='card-body py-3'>
          <div className='table-responsive'>
            <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
              <thead>
                <tr className='fw-bold text-muted'>
                  <th></th>
                  <th className="w-100px"></th>
                  <th className='min-w-500px w-100'>Title</th>
                  <th className='min-w-200px text-center'>Public Date</th>
                  <th>View</th>
                  <th className='min-w-250px text-center'>Actions</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {Array.from(content).map((value) => {
                  return <ItemsRows
                    {...props}
                    value={value}
                    key={value?.id}
                    onClickShow={onClickShow}
                    onClickDelete={onClickDelete}
                    onClickPreview={onClickPreview}
                    onClickOntrend={onClickOntrend} />
                })}
              </tbody>
            </table>
          </div>
        </div>
        <div className="card-footer">
          <div className='gap-5 d-flex align-items-center justify-content-end'>
            <ul className="pagination">
              {generatePagination(page, Math.ceil(total / LIMIT))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export { ContentWrapper }

const ItemsRows = (props) => {
  const navigate = useNavigate();
  const value = get(props, 'value', {})

  const onClickShow = get(props, 'onClickShow', () => { })
  const onClickDelete = get(props, 'onClickDelete', () => { })
  const onClickPreview = get(props, 'onClickPreview', () => { })
  const onClickOntrend = get(props, 'onClickOntrend', () => { })

  const onClickEdit = () => navigate(`/content/edit/${value?.id}`)

  const TimestamptoDate = (time) => {
    const option = { year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric' }
    return new Date(time * 1000).toLocaleDateString('th-TH', option)
  }

  return <tr className={`${value.active ? "" : "bg-secondary"}`} key={value?.id}>
    <td></td>
    <td className='align-top text-center'>
      {
        value?.on_trend && <>
          <div className='btn btn-success border btn-sm fs-8 me-2 px-4 py-1 mb-3' style={{ 'cursor': "auto" }}>
            <span>Trending News</span>
          </div>
        </>
      }
      <div className="w-125px">
        {value?.image && <img className="w-125px" alt={value?.title} src={imageToUrl(value?.image)} />}
      </div>
    </td>
    <td className='align-top'>
      <div className='mb-4 d-flex justify-content-start flex-column'>
        <h4 className='text-dark fw-bold fs-6 one-line-ellipsis'>{value?.title}</h4>
        <span className='text-muted fw-light text-muted d-block fs-7'>{value?.short_description}</span>
      </div>
      <div>
        <div className='d-inline-block'>
          {Array.from(value?.group || []).map((v, k) => {
            return <div key={k} className='btn text-black border btn-sm fs-8 me-2 px-4 py-1 mb-2' style={{ 'cursor': "auto" }}>{v}</div>
          })}
        </div>
      </div>
      {Boolean(Array.from(value?.category || []).length) && <div>
        <div className='d-inline-block'>
          {Array.from(value?.category || []).map((v, k) => {
            return <div key={k} className='btn text-black border btn-sm fs-8 me-2 px-4 py-1 mb-2' style={{ 'cursor': "auto" }}>{v}</div>
          })}
        </div>
      </div>}
      {Boolean(Array.from(value?.hashtag || []).length) && <div>
        <div className='d-inline-block'>
          {Array.from(value?.hashtag || []).map((v, k) => {
            return <div key={k} className='btn text-black border btn-sm fs-8 me-2 px-4 py-1 mb-2' style={{ 'cursor': "auto" }}>{`#${v}`}</div>
          })}
        </div>
      </div>}
    </td>
    <td className='align-top text-center'>
      <p className='text-dark fw-light fs-7 m-0 one-line-ellipsis'>{TimestamptoDate(value?.public_date)} น.</p>
    </td>
    <td className='align-top text-center'>
      <p className='text-dark fw-light fs-7 m-0 one-line-ellipsis'>{(value?.view || 0).toLocaleString()}</p>
    </td>
    <td className='align-top text-center'>
      <div className='gap-1 d-flex justify-content-end flex-shrink-0'>
        {/* Show */}
        <div className='gap-1 d-flex flex-column align-items-center'>
          <button href='#' className='btn btn-icon btn-bg-light btn-sm me-1' onClick={() => onClickShow(value?.id, !value?.active)}>
            {value?.active ? <i className="fa-regular fa-eye-slash"></i> : <i className="fa-regular fa-eye"></i>}
          </button>
          <span className='fs-8 text-gray-700'>{value?.active ? "Hide" : "Show"}</span>
        </div>
        {/* Trending */}
        <div className='gap-1 d-flex flex-column align-items-center'>
          <button href='#' className='btn btn-icon btn-bg-light btn-sm me-1' onClick={() => onClickOntrend(value?.id, !value?.on_trend)}>
            {value?.on_trend ? <i className="bi bi-bookmark-check-fill"></i> : <i className="bi bi-flag-fill"></i>}
          </button>
          <span className='fs-8 text-gray-700'>Trending</span>
        </div>
        {/* Preview */}
        <div className='gap-1 d-flex flex-column align-items-center'>
          <button href='#' className='btn btn-icon btn-bg-light btn-sm me-1' onClick={() => onClickPreview(value?.id)}>
            <i className="bi bi-box-arrow-up-right"></i>
          </button>
          <span className='fs-8 text-gray-700'>Preview</span>
        </div>
        {/* Edit */}
        <div className='gap-1 d-flex flex-column align-items-center' onClick={() => onClickEdit(value?.id)}>
          <button className='btn btn-icon btn-bg-light btn-sm me-1'>
            <i className="fa-solid fa-pencil"></i>
          </button>
          <span className='fs-8 text-gray-700'>Edit</span>
        </div>
        {/* Remove */}
        <div className='gap-1 d-flex flex-column align-items-center'>
          <button className='btn btn-icon btn-bg-light btn-sm me-1' data-bs-toggle="modal" data-bs-target={`#modal-${value?.id}`}>
            <i className="fa-sharp fa-solid fa-trash"></i>
          </button>
          <span className='fs-8 text-gray-700'>Remove</span>
        </div>
      </div>

      {/* modal */}
      <div className="modal fade" tabIndex={-1} id={`modal-${value?.id}`}>
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body mt-6 min-h-150px d-flex align-items-center justify-content-center">
              <div className="text-center">
                <p className='text-muted fw-semibold mb-0'>You want to Remove content</p>
                <p className='text-dark fw-bold fs-6 mb-0'> "{value?.title}" </p>
              </div>
            </div>
            <div className="modal-footer p-3">
              <button type="button" className="btn btn-sm btn-light" data-bs-dismiss="modal"> Cancal</button>
              <button type="button" className="btn btn-sm btn-light-danger" data-bs-dismiss="modal" onClick={() => onClickDelete(value?.id)}>
                Remove
              </button>
            </div>
          </div>
        </div>
      </div>
    </td>
    <td></td>
  </tr>
}