import { useEffect, useState } from 'react'
import { get } from 'lodash'
import Cookies from 'js-cookie'

import FromTermAndConditions from './FromTermAndConditions'

import { USER_TOKEN_KEY } from '../../modules/auth/core/_cookie'
import { apiGetPageBySlug, apiCreateOrUpdatePage } from '../../modules/auth/core/_requestPage'

const TermAndConditionsWrapper = () => {
  const [content, setContent] = useState([])

  const enableSplashScreen = () => {
    const splashScreen = document.getElementById('splash-screen')
    if (splashScreen) splashScreen.style.setProperty('display', 'flex')
  }

  const disableSplashScreen = () => {
    const splashScreen = document.getElementById('splash-screen')
    if (splashScreen) splashScreen.style.setProperty('display', 'none')
  }

  const getTermAndConditions = () => {
    enableSplashScreen()
    apiGetPageBySlug('term')
      .then((res) => setContent(JSON.parse(get(res, 'data.data.data', ""))))
      .catch((error) => {
        if (String(error?.response?.data?.message || "").includes("Token")) {
          enableSplashScreen()
          Cookies.remove(USER_TOKEN_KEY)
          setTimeout(() => {
            window.location.reload()
          }, 500)
        }
      })
      .finally(() => setTimeout(() => { disableSplashScreen() }, 200))
  }

  const setTermAndConditions = (value) => {
    enableSplashScreen()
    apiCreateOrUpdatePage('term', value)
      .then(console.log)
      .catch(console.error)
      .finally(() => getTermAndConditions())
  }

  useEffect(() => {
    getTermAndConditions()
  }, [])

  return <div className="container" style={{ minHeight: '90vh' }}>
    {content?.title && <FromTermAndConditions
      title={content.title || ""}
      content_detail={content?.content_detail || ""}
      onSubmit={setTermAndConditions} />}
  </div>
}

export default TermAndConditionsWrapper