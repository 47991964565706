import { useNavigate } from 'react-router-dom'
import { apiPostContent, apiCreateImageContent } from '../../modules/auth/core/_requestContent'

import FromBanner from './FromContent'

const CreateContentWrapper = () => {
  const navigate = useNavigate();
  const enableSplashScreen = () => {
    const splashScreen = document.getElementById('splash-screen')
    if (splashScreen) splashScreen.style.setProperty('display', 'flex')
  }

  const onSubmit = (value) => {
    const data = {
      title: value.title,
      content_detail: value.content_detail,
      short_description: value.short_description,
      public_date: value.public_date,
      group: value.group,
      hashtag: value.hashtag,
      active: true,
      on_trend: false,
      category: value.category,
      content_vdo: value.content_vdo,
      content_vdo_type: value.content_vdo_type,
    }

    enableSplashScreen()
    apiPostContent(data)
      .then((res) => apiCreateImageContent(res?.data?.data?.id, value.file))
      .then(console.log)
      .catch(console.error)
      .finally(() => navigate('/content'))
  }

  return (
    <div className="container" style={{ minHeight: '90vh' }}>
      <FromBanner type="create" onSubmit={onSubmit} onClickOntrend={() => { }} onCancel={() => navigate('/content')} />
    </div>
  )
}

export { CreateContentWrapper }
