import Cookies from 'js-cookie'
import { USER_TOKEN_KEY } from '../../../../app/modules/auth/core/_cookie'

const Navbar = () => {
  const enableSplashScreen = () => {
    const splashScreen = document.getElementById('splash-screen')
    if (splashScreen) splashScreen.style.setProperty('display', 'flex')
  }

  const onClickLogout = () => {
    Cookies.remove(USER_TOKEN_KEY)

    enableSplashScreen()
    setTimeout(() => {
      window.location.reload()
    }, 500)
  }

  return (
    <div className='app-navbar flex-shrink-0'>
      <div>
        <div className='gap-1 d-flex flex-column align-items-center'>
          <button className='btn-sm btn btn-outline btn-outline-dashed btn-outline-danger btn-active-light-danger me-1' onClick={() => onClickLogout()}>
            <i className="fa-solid fa-right-from-bracket me-2"></i>
            <span className='fs-8 text-danger'>Logout</span>
          </button>
        </div>
      </div>
    </div>
  )
}

export { Navbar }
