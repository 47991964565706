import { useEffect, useState, useRef } from 'react'
import { useFormik } from 'formik'

import _, { get } from 'lodash'
import { Collapse } from 'reactstrap'


import { imageToUrl } from '../../../_metronic/helpers'

const FromConfig = (props) => {
  const { value, onSubmit } = props
  const ref = useRef(null)
  const ref_meta = useRef(null)
  const [imagePreview, setImagePreview] = useState('')
  const [imageMetaPreview, setImageMetaPreview] = useState('')

  const formik = useFormik({
    initialValues: {
      site_info: get(value, 'site_info', ''),
      site_description: get(value, 'site_description', ''),
      channel: get(value, 'channel', ''),
      copy_rights: get(value, 'copy_rights', ''),
      logo_image: get(value, 'logo_image', ''),
      mail: get(value, 'mail', ''),
      phone: get(value, 'phone', ''),
      address: get(value, 'address', ''),
      location: get(value, 'location', ''),
      social_x: get(value, 'social_x', ''),
      social_tiktok: get(value, 'social_tiktok', ''),
      social_youtube: get(value, 'social_youtube', ''),
      social_facebook: get(value, 'social_facebook', ''),
      meta_image: get(value, 'meta_image', ''),
      meta_title: get(value, 'meta_title', ''),
      meta_description: get(value, 'meta_description', ''),
      file: null,
      file_meta: null,
    },
    validate: (value) => {
      let errors = {};

      if (!Boolean(value.site_info))
        errors.site_info = 'Please enter a Website infomation.'

      if (!Boolean(value.site_description))
        errors.site_description = 'Please enter a Description.'

      if (!Boolean(value.channel))
        errors.channel = 'Please enter a Channel.'

      if (!Boolean(value.copy_rights))
        errors.copy_rights = 'Please enter a Copy rights.'

      if (!Boolean(value.mail))
        errors.mail = 'Please enter a Email.'

      if (!Boolean(value.address))
        errors.address = 'Please enter a Address.'

      if (!Boolean(value.location))
        errors.location = 'Please enter a Map Location.'

      if (!Boolean(value.social_x))
        errors.social_x = 'Please enter a Social.'

      if (!Boolean(value.social_tiktok))
        errors.social_tiktok = 'Please enter a Social.'

      if (!Boolean(value.social_youtube))
        errors.social_youtube = 'Please enter a Social.'

      if (!Boolean(value.social_facebook))
        errors.social_facebook = 'Please enter a Social.'

      if (!Boolean(value.meta_title))
        errors.meta_title = 'Please enter a Meta title.'

      if (!Boolean(value.meta_description))
        errors.meta_description = 'Please enter a Meta description.'

      if (!Boolean(imageMetaPreview)) {
        if (!Boolean(value.file_meta))
          errors.file_meta = "Please enter Image size 1140x640"
      }

      return errors
    },
    onSubmit: async (value) => {
      return onSubmit(value)
    },
  });

  const onChangeInput = () => {
    const file = get(ref, 'current.files[0]', null)
    if (!Boolean(file)) {
      ref.current.value = null
      setImagePreview('')
      formik.setFieldValue('file', null)
      return null
    }

    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      const img = new Image();
      img.src = reader.result;
      img.onload = () => {
        setImagePreview(reader.result)
        formik.setFieldValue('file', ...ref.current.files)
      }
    }
  }

  const onChangeMetaInput = () => {
    const file = get(ref_meta, 'current.files[0]', null)
    if (!Boolean(file)) {
      ref_meta.current.value = null
      setImageMetaPreview('')
      formik.setFieldValue('file_meta', null)
      return null
    }

    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      const img = new Image();
      img.src = reader.result;
      img.onload = () => {
        const width = get(img, 'width', 0)
        const height = get(img, 'height', 0)
        if (width === 1140 && height === 640) {
          setImageMetaPreview(reader.result)
          formik.setFieldValue('file_meta', ...ref_meta.current.files)
        } else {
          setImageMetaPreview('')
          ref.current.value = null
          formik.setFieldValue('file_meta', null)
        }
      }
    }
  }

  useEffect(() => {
    if (!_.isEmpty(value)) {
      setImagePreview(get(value, 'logo_image', ''))
      setImageMetaPreview(get(value, 'meta_image', ''))
    }
  }, [value])

  return <div className="card card-xxl-stretch mb-5 mb-xl-8">
    <div className='card-header py-6'>
      <div className='card-title w-100 d-flex align-items-center justify-content-between'>
        <div>
          <h4 className='card-label fw-bold fs-3 mb-1'>Config</h4>
          <span className='text-muted mt-1 fw-semibold fs-7'>
            Config : Honekrasae
          </span>
        </div>
      </div>
    </div>

    <div className='card-body'>
      {/* Infomation */}
      <div className="row">
        <div className='col-12 col-xl-12'>
          <div className="mb-4">
            <h4 className='card-label fw-bold fs-3 mb-1'>Infomation</h4>
          </div>
        </div>
        <div className='col-12 col-xl-7'>
          <div className="row">
            <div className='col-8'>
              <div className="mb-4">
                <label className="required fw-bold form-label">Website infomation</label>
                <input
                  type="text"
                  name="site_info"
                  placeholder="Infomation"
                  className="form-control form-control-white"
                  value={formik.values.site_info}
                  onChange={(e) => formik.setFieldValue('site_info', e.target.value)} />
                <Collapse isOpen={Boolean(formik.errors.site_info)}>
                  <div className="pt-2">
                    <span className="text-danger fw-light fs-8">** {formik.errors.site_info}</span>
                  </div>
                </Collapse>
              </div>
            </div>
            <div className='col-4'>
              <div className="mb-4">
                <label className="required fw-bold form-label">Channel</label>
                <input
                  type="text"
                  name="channel"
                  placeholder="channel"
                  className="form-control form-control-white"
                  value={formik.values.channel}
                  onChange={(e) => formik.setFieldValue('channel', e.target.value)} />
                <Collapse isOpen={Boolean(formik.errors.channel)}>
                  <div className="pt-2">
                    <span className="text-danger fw-light fs-8">** {formik.errors.channel}</span>
                  </div>
                </Collapse>
              </div>
            </div>

            {/* Description */}
            <div className='col-12'>
              <div className="mb-4">
                <label className="required fw-bold form-label">Description</label>
                <textarea
                  rows={3}
                  type="text"
                  name="site_description"
                  placeholder="Description"
                  className="form-control form-control-white"
                  value={formik.values.site_description}
                  onChange={(e) => formik.setFieldValue('site_description', e.target.value)} />
                <Collapse isOpen={Boolean(formik.errors.site_description)}>
                  <div className="pt-2">
                    <span className="text-danger fw-light fs-8">** {formik.errors.site_description}</span>
                  </div>
                </Collapse>
              </div>
            </div>
            <div className='col-12 col-xl-12'>
              <div className="mb-4">
                <label className="required fw-bold form-label">Copy rights</label>
                <input
                  type="text"
                  name="copy_rights"
                  placeholder="Copy rights"
                  className="form-control form-control-white"
                  value={formik.values.copy_rights || ''}
                  onChange={(e) => formik.setFieldValue('copy_rights', e.target.value)} />
                <Collapse isOpen={Boolean(formik.errors.copy_rights)}>
                  <div className="pt-2">
                    <span className="text-danger fw-light fs-8">** {formik.errors.copy_rights}</span>
                  </div>
                </Collapse>
              </div>
            </div>
          </div>
        </div>

        {/* image  */}
        <div className='col-12 col-xl-5'>
          <div className="mb-4">
            <div className="pt-4 d-flex align-items-center justify-content-center mb-4" style={{ minHeight: 197, background: '#f5f8fa', borderRadius: "0.475rem" }}>
              {imagePreview && <img src={imageToUrl(imagePreview)} alt="images" className="rounded" style={{ maxHeight: 180 }} />}
            </div>
            <label className="fw-bold form-label">Logo</label>
            <input ref={ref} type="file" accept="image/png, image/jpeg" className="form-control form-control-white" onChange={onChangeInput} />
            <Collapse isOpen={Boolean(formik.errors.file)}>
              <div className="pt-2">
                <span className="text-danger fw-light fs-8">** {formik.errors.file}</span>
              </div>
            </Collapse>
          </div>
        </div>
      </div>

      {/* Detail */}
      <div className="row">
        <div className='col-12 col-xl-12'>
          <div className='border-top mt-4 pt-4'>
            <div className="mb-4">
              <h4 className='card-label fw-bold fs-3 mb-1'>Detail</h4>
            </div>
          </div>
        </div>
        <div className='col-12 col-xl-6'>
          <div className="mb-4">
            <label className="required fw-bold form-label">Email</label>
            <input
              type="text"
              name="mail"
              placeholder="Email"
              className="form-control form-control-white"
              value={formik.values.mail || ''}
              onChange={(e) => formik.setFieldValue('mail', e.target.value)} />
            <Collapse isOpen={Boolean(formik.errors.mail)}>
              <div className="pt-2">
                <span className="text-danger fw-light fs-8">** {formik.errors.mail}</span>
              </div>
            </Collapse>
          </div>
        </div>
        <div className='col-12 col-xl-6'>
          <div className="mb-4">
            <label className="fw-bold form-label">Phone</label>
            <input
              type="text"
              name="mail"
              placeholder="phone"
              className="form-control form-control-white"
              value={formik.values.phone || ''}
              onChange={(e) => formik.setFieldValue('phone', e.target.value)} />
            <Collapse isOpen={Boolean(formik.errors.phone)}>
              <div className="pt-2">
                <span className="text-danger fw-light fs-8">** {formik.errors.phone}</span>
              </div>
            </Collapse>
          </div>
        </div>

        <div className='col-12 col-xl-6'>
          <div className='row'>
            <div className='col-12 col-xl-12'>
              <div className="mb-4">
                <label className="required fw-bold form-label">Address</label>
                <textarea
                  rows={4}
                  name="address"
                  placeholder="Address"
                  className="form-control form-control-white"
                  value={formik.values.address || ''}
                  onChange={(e) => formik.setFieldValue('address', e.target.value)} />
                <Collapse isOpen={Boolean(formik.errors.address)}>
                  <div className="pt-2">
                    <span className="text-danger fw-light fs-8">** {formik.errors.address}</span>
                  </div>
                </Collapse>
              </div>
            </div>

            {/* Location */}
            <div className='col-12'>
              <div className="mb-4">
                <label className="required fw-bold form-label">Map Location</label>
                <textarea
                  rows={10}
                  name="location"
                  placeholder="Map Location"
                  className="form-control form-control-white"
                  value={formik.values.location || ''}
                  onChange={(e) => formik.setFieldValue('location', e.target.value)} />
                <Collapse isOpen={Boolean(formik.errors.location)}>
                  <div className="pt-2">
                    <span className="text-danger fw-light fs-8">** {formik.errors.location}</span>
                  </div>
                </Collapse>
              </div>
            </div>
          </div>
        </div>

        <div className='col-12 col-xl-6'>
          <div className='row'>
            <div className='col-12 col-xl-12'>
              <div className="mb-4" style={{ minHeight: 400, background: '#f5f8fa', borderRadius: "0.475rem" }}>
                <div dangerouslySetInnerHTML={{ __html: formik.values.location }} />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Social media */}
      <div className="row">
        <div className='col-12 col-xl-12'>
          <div className='border-top mt-4 pt-4'>
            <div className="mb-4">
              <h4 className='card-label fw-bold fs-3 mb-1'>Social media</h4>
            </div>
          </div>
        </div>

        {/* Facebook */}
        <div className='col-12 col-xl-6'>
          <div className="mb-4">
            <label className="required fw-bold form-label">Facebook</label>
            <div className='d-flex'>
              <button className="btn btn-icon btn-bg-light me-3">
                <i className="fa-brands fa-facebook fs-4 text-muted"></i>
              </button>
              <input
                type="text"
                name="social_facebook"
                placeholder="facebook"
                className="form-control form-control-white"
                value={formik.values.social_facebook || ''}
                onChange={(e) => formik.setFieldValue('social_facebook', e.target.value)} />
            </div>
            <Collapse isOpen={Boolean(formik.errors.social_facebook)}>
              <div className="pt-2">
                <span className="text-danger fw-light fs-8">** {formik.errors.social_facebook}</span>
              </div>
            </Collapse>
          </div>
        </div>

        {/* Twitter */}
        <div className='col-12 col-xl-6'>
          <div className="mb-4">
            <label className="required fw-bold form-label">Twitter / X</label>
            <div className='d-flex'>
              <button className="btn btn-icon btn-bg-light me-3">
                <i className="fa-brands fa-twitter fs-4 text-muted"></i>
              </button>
              <input
                type="text"
                name="social_x"
                placeholder="twitter / x"
                className="form-control form-control-white"
                value={formik.values.social_x || ''}
                onChange={(e) => formik.setFieldValue('social_x', e.target.value)} />
            </div>
            <Collapse isOpen={Boolean(formik.errors.social_x)}>
              <div className="pt-2">
                <span className="text-danger fw-light fs-8">** {formik.errors.social_x}</span>
              </div>
            </Collapse>
          </div>
        </div>

        {/* Tiktok */}
        <div className='col-12 col-xl-6'>
          <div className="mb-4">
            <label className="required fw-bold form-label">Tiktok</label>
            <div className='d-flex'>
              <button className="btn btn-icon btn-bg-light me-3">
                <i className="fa-brands fa-tiktok fs-4 text-muted"></i>
              </button>
              <input
                type="text"
                name="social_tiktok"
                placeholder="tiktok"
                className="form-control form-control-white"
                value={formik.values.social_tiktok || ''}
                onChange={(e) => formik.setFieldValue('social_tiktok', e.target.value)} />
            </div>
            <Collapse isOpen={Boolean(formik.errors.social_tiktok)}>
              <div className="pt-2">
                <span className="text-danger fw-light fs-8">** {formik.errors.social_tiktok}</span>
              </div>
            </Collapse>
          </div>
        </div>

        {/* Youtube */}
        <div className='col-12 col-xl-6'>
          <div className="mb-4">
            <label className="required fw-bold form-label">Youtube</label>
            <div className='d-flex'>
              <button className="btn btn-icon btn-bg-light me-3">
                <i className="fa-brands fa-youtube fs-4 text-muted"></i>
              </button>
              <input
                type="text"
                name="social_youtube"
                placeholder="youtube"
                className="form-control form-control-white"
                value={formik.values.social_youtube || ''}
                onChange={(e) => formik.setFieldValue('social_youtube', e.target.value)} />
            </div>
            <Collapse isOpen={Boolean(formik.errors.social_youtube)}>
              <div className="pt-2">
                <span className="text-danger fw-light fs-8">** {formik.errors.social_youtube}</span>
              </div>
            </Collapse>
          </div>
        </div>
      </div>

      <div className="row">
        <div className='col-12 col-xl-12'>
          <div className='border-top mt-4 pt-4'>
            <div className="mb-4">
              <h4 className='card-label fw-bold fs-3 mb-1'>Meta data</h4>
            </div>
          </div>
        </div>

        {/* image  */}
        <div className='col-12 col-xl-6'>
          <div className="mb-4">
            <div className="pt-4 d-flex align-items-center justify-content-center mb-4" style={{ minHeight: 100, background: '#f5f8fa', borderRadius: "0.475rem" }}>
              {imageMetaPreview && <img src={imageToUrl(imageMetaPreview)} alt="images" className="rounded" style={{ maxHeight: 280 }} />}
            </div>
            <div className="d-flex align-items-center justify-content-between">
              <label className="fw-bold form-label">Meta image</label>
              <span className="text-gray-400 mt-1 fw-semibold fs-7">1140x640 (witdh x height)</span>
            </div>
            <input ref={ref_meta} type="file" accept="image/png, image/jpeg" className="form-control form-control-white" onChange={onChangeMetaInput} />
            <Collapse isOpen={Boolean(formik.errors.file_meta)}>
              <div className="pt-2">
                <span className="text-danger fw-light fs-8">**ssss {formik.errors.file_meta}</span>
              </div>
            </Collapse>
          </div>
        </div>

        <div className='col-12 col-xl-6'>
          <div className="row">
            <div className='col-12'>
              <div className="mb-4">
                <label className="required fw-bold form-label">Meta title</label>
                <input
                  type="text"
                  name="meta_title"
                  placeholder="Infomation"
                  className="form-control form-control-white"
                  value={formik.values.meta_title}
                  onChange={(e) => formik.setFieldValue('meta_title', e.target.value)} />
                <Collapse isOpen={Boolean(formik.errors.meta_title)}>
                  <div className="pt-2">
                    <span className="text-danger fw-light fs-8">** {formik.errors.meta_title}</span>
                  </div>
                </Collapse>
              </div>
            </div>

            {/* Description */}
            <div className='col-12'>
              <div className="mb-4">
                <label className="required fw-bold form-label">Meta description</label>
                <textarea
                  rows={3}
                  type="text"
                  name="meta_description"
                  placeholder="Description"
                  className="form-control form-control-white"
                  value={formik.values.meta_description}
                  onChange={(e) => formik.setFieldValue('meta_description', e.target.value)} />
                <Collapse isOpen={Boolean(formik.errors.meta_description)}>
                  <div className="pt-2">
                    <span className="text-danger fw-light fs-8">** {formik.errors.meta_description}</span>
                  </div>
                </Collapse>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>

    <div className="card-footer">
      <div className='gap-5 d-flex align-items-center justify-content-end'>
        <button type="submit" className="btn btn-sm btn-primary" onClick={() => formik.handleSubmit()}>
          Save
        </button>
      </div>
    </div>
  </div >
}

export default FromConfig