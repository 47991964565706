import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { get } from 'lodash'
import Cookies from 'js-cookie'

import FromProgramTV from './FromProgramTV'

import { USER_TOKEN_KEY } from '../../modules/auth/core/_cookie'
import { callapiFile } from '../../modules/auth/core/_requestContent'
import { apiGetConfig, apiCreateOrUpdateConfig } from '../../modules/auth/core/_requestConfig'

const ProgramTVWrapper = () => {
  const { id } = useParams();
  const [content, setContent] = useState(null)

  const enableSplashScreen = () => {
    const splashScreen = document.getElementById('splash-screen')
    if (splashScreen) splashScreen.style.setProperty('display', 'flex')
  }

  const disableSplashScreen = () => {
    const splashScreen = document.getElementById('splash-screen')
    if (splashScreen) splashScreen.style.setProperty('display', 'none')
  }

  const getConfigProgram = () => {
    enableSplashScreen()
    apiGetConfig(id)
      .then((res) => setContent(JSON.parse(get(res, 'data.data.data', ""))))
      .catch((error) => {
        if (String(error?.response?.data?.message || "").includes("Token")) {
          enableSplashScreen()
          Cookies.remove(USER_TOKEN_KEY)
          setTimeout(() => {
            window.location.reload()
          }, 500)
        }
      })
      .finally(() => setTimeout(() => { disableSplashScreen() }, 200))
  }

  const setConfigProgram = async (value) => {
    enableSplashScreen()

    // check image
    if (typeof value.file === 'object' && value.file !== null) {
      const image = await new Promise((resolve, reject) => {
        callapiFile(value.file)
          .then((res) => resolve({ logo_image: get(res, 'data.data.filePath', '') }))
          .catch(reject)
      })

      if (image?.logo_image !== "")
        value = Object.assign({}, value, { logo_image: image?.logo_image })
    }

    apiCreateOrUpdateConfig(id, value)
      .then(console.log)
      .catch(console.error)
      .finally(() => getConfigProgram())
  }

  useEffect(() => {
    getConfigProgram()
    return () => { setContent(null) }
  }, [id])

  return <div className="container" style={{ minHeight: '90vh' }}>
    {content?.title && <FromProgramTV value={content} onSubmit={setConfigProgram} />}
  </div>
}

export default ProgramTVWrapper