import { get } from 'lodash'
import { useFormik } from 'formik'
import { Collapse } from 'reactstrap'

import { PAGE_LIST, POSITION_LIST } from './data'

const FromContent = (props) => {
  const onCancel = get(props, 'onCancel', () => { })
  const onSubmit = get(props, 'onSubmit', () => { })

  const value = get(props, 'value', {})
  const type = get(props, 'type', 'create')

  const formik = useFormik({
    initialValues: {
      title: get(value, 'title', ''),
      script: get(value, 'script', ''),
      page: get(value, 'page', ""),
      position: get(value, 'position', ""),
    },
    validate: (value) => {
      let errors = {};
      if (!Boolean(value.title))
        errors.title = 'Please enter a Live title.'

      if (!Boolean(value.script))
        errors.script = 'Please enter a Script Ads.'

      if (!Boolean(value.page))
        errors.page = 'Please select a page.'

      if (!Boolean(value.position))
        errors.position = 'Please select a position.'

      return errors
    },
    onSubmit: (value) => {
      return onSubmit(value)
    },
  });

  const onClickContentVDOType = (value) => formik.setFieldValue('live_type', value)

  return <div className="card card-xxl-stretch mb-5 mb-xl-8">
    <div className='card-header py-6'>
      <div className='card-title w-100 d-flex align-items-center justify-content-between'>
        <div>
          <h4 className='card-label fw-bold fs-3 mb-1'>{type === 'create' ? 'Create Live' : 'Edit Live'}</h4>
          <span className='text-muted mt-1 fw-semibold fs-7'>
            Create Live with platform Facebook or Youtube
          </span>
        </div>
      </div>
    </div>

    <div className='card-body'>
      <div className="row">
        <div className='col-12 col-xl-12'>
          <div className="mb-4">
            <label className="required fw-bold form-label">Title</label>
            <input
              type="text"
              name="title"
              placeholder="title"
              className="form-control form-control-white"
              value={formik.values.title}
              onChange={(e) => formik.setFieldValue('title', e.target.value)} />
            <Collapse isOpen={Boolean(formik.errors.title)}>
              <div className="pt-2">
                <span className="text-danger fw-light fs-8">** {formik.errors.title}</span>
              </div>
            </Collapse>
          </div>

        </div>

        <div className='col-12 col-xl-12'>
          <div className="mb-4">
            <label className="required fw-bold form-label">Script Ads</label>
            <textarea
              rows={3}
              type="text"
              name="script"
              placeholder="script ads"
              className="form-control form-control-white"
              value={formik.values.script}
              onChange={(e) => formik.setFieldValue('script', e.target.value)} />
            <Collapse isOpen={Boolean(formik.errors.script)}>
              <div className="pt-2">
                <span className="text-danger fw-light fs-8">** {formik.errors.script}</span>
              </div>
            </Collapse>
          </div>
        </div>

        <div className='col-12 col-xl-6'>
          <div className="mb-4">
            <label className="required fw-bold form-label">Page</label>
            <select
              className="form-select"
              value={formik.values.page}
              onChange={(e) => formik.setFieldValue('page', e.target.value)}>
              <option value="" disabled>Select pages</option>
              {PAGE_LIST.map((v) => <option value={v.key}>{v.title}</option>)}
            </select>
            <Collapse isOpen={Boolean(formik.errors.page)}>
              <div className="pt-2">
                <span className="text-danger fw-light fs-8">** {formik.errors.page}</span>
              </div>
            </Collapse>
          </div>
        </div>

        <div className='col-12 col-xl-6'>
          <div className="mb-4">
            <label className="required fw-bold form-label">Position</label>
            <select
              className="form-select"
              value={formik.values.position}
              onChange={(e) => formik.setFieldValue('position', e.target.value)}>
              <option value="" disabled>Select position</option>
              {POSITION_LIST.map((v) => <option value={v.key}>{v.title}</option>)}
            </select>
            <Collapse isOpen={Boolean(formik.errors.position)}>
              <div className="pt-2">
                <span className="text-danger fw-light fs-8">** {formik.errors.position}</span>
              </div>
            </Collapse>
          </div>
        </div>
      </div>
    </div>

    <div className="card-footer">
      <div className='gap-5 d-flex align-items-center justify-content-end'>
        <button className="btn btn-sm btn-light-primary" onClick={onCancel}>Back</button>
        <button type="submit" className="btn btn-sm btn-primary" onClick={() => formik.handleSubmit()}>
          {type === 'create' ? 'Create' : 'Save'}
        </button>
      </div>
    </div>
  </div >
}

export default FromContent